import React from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  hint: PropTypes.node,
  label: PropTypes.node,
  options: PropTypes.shape({}),
  labelTip: PropTypes.string,
};

const defaultProps = {
  className: null,
  hint: null,
  label: null,
  labelTip: null,
  options: {},
};

class RenderTextareaField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.textarea = React.createRef();
  }

  componentDidMount() {
    const { options } = this.props;

    if (Object.keys(options).length) {
      import(/* webpackChunkName: "tributejs" */ 'tributejs')
        .then(({ default: TributeJs }) => {
          const tribute = new TributeJs(options);
          tribute.attach(this.textarea.current);
        });
    }
  }

  render() {
    const {
      input, meta, hint, labelTip,
      className, label, options, ...rest
    } = this.props;

    return (
      <div className={className}>
        <Label label={label} tip={labelTip} />
        <div className="field-container">
          <textarea className="form-control" ref={this.textarea} {...input} {...rest} />
          <ErrorMessage {...meta} />
        </div>
        {hint && <span className="hint">{hint}</span>}
      </div>
    );
  }
}

RenderTextareaField.propTypes = propTypes;
RenderTextareaField.defaultProps = defaultProps;

export default RenderTextareaField;
