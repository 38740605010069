import React from 'react';
import { Field } from 'redux-form';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import RenderField from 'common-components/form/RenderField';
import RenderDateField from 'common-components/form/RenderDateField';
import RenderSelectField from 'common-components/form/RenderSelectField';
import Label from 'common-components/form/Label';
import {
  required, email, date, phone, url, age, isAsset, firewallUnsupportedCharacters,
} from 'utils/form/validations';
import CvInfo from './CvInfo';
import IdDocumentField from './IdDocumentField';

const propTypes = {
  cvData: PropTypes.shape({
    fileName: PropTypes.string,
    fileType: PropTypes.string,
    fileUrl: PropTypes.string,
  }),
  dniType: PropTypes.string,
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  renderField: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  userLoggedIn: PropTypes.bool,
  validExistingEmail: PropTypes.func,
  showExistingEmail: PropTypes.bool,
  showExistingCandidacy: PropTypes.bool,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  idDocumentFieldsForm: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  anonymousJob: PropTypes.bool,
};

const defaultProps = {
  cvData: {
    fileName: undefined,
    fileType: undefined,
    fileUrl: undefined,
  },
  dniType: undefined,
  systemFieldsForm: [],
  idDocumentFieldsForm: {
    fields: [],
  },
  userLoggedIn: undefined,
  showExistingEmail: false,
  showExistingCandidacy: false,
  validExistingEmail: undefined,
  anonymousJob: false,
};

function requiredFieldLabel(label) {
  return (
    <span className="label" title={label}>
      {label}
      <span className="require">*</span>
    </span>
  );
}

const renderCVLink = (fieldLabel, fileName, fileUrl, assetField, messages) => (
  <div className="block-input">
    <Label label={fieldLabel(assetField, messages['jobs.attach_cv'])} />
    <div className="field-container">
      <span className="info">
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          {fileName}
        </a>
      </span>
    </div>
  </div>
);

function PersonalInfo(props) {
  const {
    userLoggedIn, fieldLabel, fieldValidation, formName, renderField,
    systemFieldsForm: {
      professional_metadata_title: titleField,
      user_birth_date: birthDateField,
      user_birth_place: birthPlaceField,
      assets: assetField,
      professional_metadata_phone: phoneField,
      professional_metadata_videocurriculum: videocurriculumField,
      gender: genderField,
    },
    idDocumentFieldsForm, dniType,
    cvData: { fileName, fileType, fileUrl },
    intl: { messages },
    validExistingEmail, showExistingEmail, showExistingCandidacy,
    genders,
    anonymousJob,
  } = props;

  const userMetadata = 'user_representations_attributes[0].user_metadata_attributes.';
  const profMetadata = 'user_representations_attributes[0].professional_metadata_attributes.';

  const placeholders = {
    day: `${messages['jobs.time.day']}`,
    month: `${messages['jobs.time.month']}`,
    year: `${messages['jobs.time.year']}`,
  };

  return (
    <React.Fragment>
      {
        !userLoggedIn &&
          <div data-input-name="user.email" data-label-text={messages['jobs.email']} className="row form-group">
            <Field
              className="col-12"
              component={RenderField}
              label={requiredFieldLabel(messages['jobs.email'])}
              name="email"
              type="text"
              validate={[required, email]}
              placeholder={messages['jobs.email']}
              onBlur={validExistingEmail}
            />
          </div>
      }
      {
        showExistingCandidacy && (
          <div className="special-error form-group">
            <span className="error">
              <span>{messages['jobs.job_centers.existing_candidacy.message']}</span>
            </span>
          </div>
        )
      }
      {
        !showExistingCandidacy && showExistingEmail && !anonymousJob && (
          <div className="special-error form-group">
            <span className="info">
              <span>{messages['jobs.job_centers.existing_email.message']}</span>
            </span>
          </div>
        )
      }
      <div className="row form-group">
        <div className="col-6">
          {
            renderField(
              requiredFieldLabel(messages['jobs.name']),
              `${userMetadata}first_name`,
              [required, firewallUnsupportedCharacters],
              `${messages['jobs.name']}`,
              `user.${userMetadata}first_name`,
            )
          }
        </div>
        <div className="col-6">
          {
            renderField(
              requiredFieldLabel(messages['jobs.surnames']),
              `${userMetadata}last_name`,
              [required, firewallUnsupportedCharacters],
              `${messages['jobs.surnames']}`,
              `user.${userMetadata}last_name`,
            )
          }
        </div>
      </div>
      {
        assetField && (
          (fileUrl !== undefined && isAsset(fileType) === undefined) ?
            renderCVLink(fieldLabel, fileName, fileUrl, assetField, messages)
            : (
              <CvInfo
                fieldLabel={fieldLabel}
                assetField={assetField}
              />
            )
        )
      }
      {
        genderField && (
          <div data-input-name={`user.${userMetadata}gender`} data-label-text={messages['jobs.gender']} className="row form-group">
            <Field
              className="col-6"
              component={RenderSelectField}
              label={fieldLabel(genderField, messages['jobs.gender'])}
              name={`${userMetadata}gender`}
              options={genders}
              validate={fieldValidation(genderField)}
              placeholder={`${messages['jobs.gender']}`}
              clearable
            />
          </div>
        )
      }
      { (idDocumentFieldsForm.fields !== undefined) && (idDocumentFieldsForm.fields.length > 0) &&
        <IdDocumentField
          idDocumentFieldsForm={idDocumentFieldsForm}
          dniType={dniType}
        />
      }
      {
        titleField &&
          <div className="row form-group">
            <div className="col-12">
              {
                renderField(
                  fieldLabel(titleField, messages['jobs.profession']),
                  `${profMetadata}title`,
                  fieldValidation(titleField, firewallUnsupportedCharacters),
                  `${messages['jobs.profession']}`,
                  `user.${profMetadata}title`,
                )
              }
            </div>
          </div>
      }
      {
        birthDateField && (
          <div data-input-name={`user.${userMetadata}birth_date`} data-label-text={messages['jobs.birthdate']} className="form-group">
            <Field
              component={RenderDateField}
              formName={formName}
              label={fieldLabel(birthDateField, messages['jobs.birthdate'])}
              name={`${userMetadata}birth_date`}
              selectorClass="col-3"
              type="text"
              validate={fieldValidation(birthDateField, [age, date])}
              placeholders={placeholders}
            />
          </div>
        )
      }
      {
        birthPlaceField &&
          <div className="row form-group">
            <div className="col-6">
              {
                renderField(
                  fieldLabel(birthPlaceField, messages['jobs.birth_place']),
                  `${userMetadata}birth_place`,
                  fieldValidation(birthPlaceField, firewallUnsupportedCharacters),
                  `${messages['jobs.birth_place']}`,
                  `user.${userMetadata}birth_place`,
                )
              }
            </div>
          </div>
      }
      {
        phoneField &&
          <div className="row form-group">
            <div className="col-6">
              {
                renderField(
                  fieldLabel(phoneField, messages['jobs.phone']),
                  `${profMetadata}phone`,
                  fieldValidation(phoneField, phone),
                  messages['jobs.phone'],
                  `user.${profMetadata}phone`,
                  'tel',
                )
              }
            </div>
          </div>
      }

      {
        videocurriculumField &&
          <div className="row form-group">
            <div className="col-6">
              {
                renderField(
                  fieldLabel(videocurriculumField, messages['jobs.videocurriculum']),
                  `${profMetadata}videocurriculum`,
                  fieldValidation(videocurriculumField, url),
                  `${messages['jobs.videocurriculum']}`,
                  `user.${profMetadata}videocurriculum`,
                )
              }
            </div>
          </div>
      }
    </React.Fragment>
  );
}

PersonalInfo.propTypes = propTypes;
PersonalInfo.defaultProps = defaultProps;
export default injectIntl(PersonalInfo);
