import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderTextareaField from 'common-components/form/RenderTextareaField';
import RenderField from 'common-components/form/RenderField';
import RenderDateTimePickerField from 'common-components/form/RenderDateTimePickerField';
import RenderSelectField from 'common-components/form/RenderSelectField';
import RenderRadioFields from 'common-components/form/RenderRadioFields';
import RenderInputNumberField from 'common-components/form/RenderInputNumberField';
import RenderRangeField from 'common-components/form/RenderRangeField';
import RenderFileField from 'common-components/form/RenderFileField';
import { required, isInteger, isNumberNormalized, firewallUnsupportedCharacters } from 'utils/form/validations';
import { cleanFileName } from 'utils/form/normalizations';

const propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    mandatory: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    option: PropTypes.number,
    response: PropTypes.string,
    description: PropTypes.string,
    limitMin: PropTypes.number,
    limitMax: PropTypes.number,
  }),
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  field: {
    label: undefined,
    options: null,
    response: null,
  },
};

class CustomField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderEditableSimpleCustomField = this.renderEditableSimpleCustomField.bind(this);
    this.renderEditableSelectCustomField = this.renderEditableSelectCustomField.bind(this);
    this.renderEditableDateField = this.renderEditableDateField.bind(this);
    this.renderEditableFileCustomField = this.renderEditableFileCustomField.bind(this);
    this.fieldType = this.fieldType.bind(this);
    this.fieldLabel = this.fieldLabel.bind(this);
  }

  fieldLabel(mandatory, label) {
    return mandatory ? (
      <span title={label}>
        {label}
        <span className="require">*</span>
      </span>
    )
      : label;
  }

  fieldType() {
    const {
      field: { type, mandatory },
    } = this.props;

    const dateOptions = { dateFormat: 'Y-m-d' };
    const dateTimeOptions = {
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
    };

    const validations = mandatory ? [required] : [];

    switch (type) {
      case 'CustomField::Fields::DateTimeType':
        return (this.renderEditableDateField(dateTimeOptions, validations));
      case 'CustomField::Fields::DateType':
        return (this.renderEditableDateField(dateOptions, validations));
      case 'CustomField::Fields::IntegerType':
        validations.push(isInteger, isNumberNormalized);
        return (this.renderEditableSimpleCustomField(RenderInputNumberField, validations));
      case 'CustomField::Fields::MultiSelectType':
        return (this.renderEditableMultiSelectCustomField(RenderSelectField, validations));
      case 'CustomField::Fields::RadioType':
        return (this.renderEditableSelectCustomField(RenderRadioFields, validations));
      case 'CustomField::Fields::RangeType':
        return (this.renderEditableRangeCustomField(RenderRangeField, validations));
      case 'CustomField::Fields::SelectType':
        return (this.renderEditableSelectCustomField(RenderSelectField, validations));
      case 'CustomField::Fields::StringType':
        return (this.renderEditableSimpleCustomField(RenderField, validations.concat(firewallUnsupportedCharacters)));
      case 'CustomField::Fields::TextType':
        return (this.renderEditableSimpleCustomField(RenderTextareaField, validations.concat(firewallUnsupportedCharacters)));
      case 'CustomField::Fields::FileType':
        return (this.renderEditableFileCustomField(RenderFileField, validations));
      default:
        return (this.renderEditableSimpleCustomField(RenderField, validations));
    }
  }

  renderEditableFileCustomField(type, validations) {
    const {
      field: { label, mandatory, description },
      name,
    } = this.props;

    return (
      <div data-input-name={`custom_field_groups.${name}.asset`} data-label-text={label} className="form-group">
        <Field
          className="block-input"
          component={type}
          label={this.fieldLabel(mandatory, label)}
          placeholder={label}
          hint={description}
          validate={validations}
          normalize={cleanFileName}
          name={`${name}.asset`}
        />
      </div>
    );
  }

  renderEditableSimpleCustomField(component, validations) {
    const {
      field: { label, mandatory, description },
      name,
    } = this.props;

    return (
      <div data-input-name={`custom_field_groups.${name}.response`} data-label-text={label} className="form-group">
        <Field
          className="block-input"
          component={component}
          label={this.fieldLabel(mandatory, label)}
          placeholder={label}
          hint={description}
          type="text"
          validate={validations}
          name={`${name}.response`}
        />
      </div>
    );
  }

  renderEditableSelectCustomField(component, validations) {
    const {
      field: {
        label, mandatory, options, description,
      },
      name,
    } = this.props;

    return (
      <div data-input-name={`custom_field_groups.${name}.option_id`} data-label-text={label} className="form-group">
        <Field
          className="block-input"
          component={component}
          label={this.fieldLabel(mandatory, label)}
          placeholder={label}
          hint={description}
          validate={validations}
          options={options}
          clearable
          name={`${name}.option_id`}
        />
      </div>
    );
  }

  renderEditableMultiSelectCustomField(component, validations) {
    const {
      field: {
        label, mandatory, options, description,
      },
      name,
    } = this.props;

    return (
      <div data-input-name={`custom_field_groups.${name}.options`} data-label-text={label} className="form-group">
        <Field
          className="block-input"
          component={component}
          label={this.fieldLabel(mandatory, label)}
          placeholder={label}
          hint={description}
          validate={validations}
          options={options}
          name={`${name}.options`}
          isMulti
          clearable
        />
      </div>
    );
  }

  renderEditableRangeCustomField(component, validations) {
    const {
      field: {
        label, mandatory, limitMin, limitMax, description,
      },
      name,
    } = this.props;

    return (
      <div data-input-name={`custom_field_groups.${name}.range`} data-label-text={label} className="form-group">
        <Field
          className="block-input"
          component={component}
          label={this.fieldLabel(mandatory, label)}
          hint={description}
          validate={validations}
          name={`${name}.range`}
          min={limitMin}
          max={limitMax}
          marks={{
            [limitMin]: limitMin,
            [limitMax]: limitMax,
          }}
          defaultValue={[limitMin, limitMax]}
        />
      </div>
    );
  }

  renderEditableDateField(pickerFieldOptions, validations) {
    const {
      field: { label, mandatory, description },
      name,
    } = this.props;

    return (
      <div data-input-name={`custom_field_groups.${name}.range`} data-label-text={label} className="form-group">
        <Field
          className="block-input"
          component={RenderDateTimePickerField}
          label={this.fieldLabel(mandatory, label)}
          placeholder={label}
          hint={description}
          validate={validations}
          options={pickerFieldOptions}
          name={`${name}.response`}
        />
      </div>
    );
  }

  render() {
    return (
      this.fieldType()
    );
  }
}

CustomField.propTypes = propTypes;
CustomField.defaultProps = defaultProps;

export default CustomField;
