import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  renderField: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
};

export default function JobPreference(props) {
  const {
    fieldLabel, fieldValidation, renderField,
    systemFieldsForm: {
      job_preference_desired_salary: desiredSalaryField,
      job_preference_min_salary: minSalaryField,
      job_preference_preferred_job: preferredJobField,
    },
  } = props;

  return (
    <div>
      {
        desiredSalaryField &&
          renderField(
            fieldLabel(desiredSalaryField, <FormattedMessage id="jobs.desired_salary" />),
            'desired_salary',
            fieldValidation(desiredSalaryField),
            'jobs.desired_salary',
          )
      }
      {
        minSalaryField &&
          renderField(
            fieldLabel(minSalaryField, <FormattedMessage id="jobs.minimum_salary" />),
            'min_salary_accepted',
            fieldValidation(minSalaryField),
            'jobs.minimum_salary',
          )
      }
      {
        preferredJobField &&
          renderField(
            fieldLabel(preferredJobField, <FormattedMessage id="jobs.preferred_job" />),
            'preferred_job',
            fieldValidation(preferredJobField),
            'jobs.preferred_job',
          )
      }
    </div>
  );
}

JobPreference.propTypes = propTypes;
JobPreference.defaultProps = defaultProps;
