import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common-components/Icon';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Field, change, fieldArrayFieldsPropTypes } from 'redux-form';
import RenderFileField from 'common-components/form/RenderFileField';

import {
  isAsset,
  required,
} from 'utils/form/validations';

const propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  formName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  assetField: PropTypes.shape().isRequired,
};

class RenderAttachFileRegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.addFields = this.addFields.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { fields, formName, dispatch } = this.props;
    if (!fields.length) {
      fields.push();
      dispatch(change(
        formName,
        'user.user_representations_attributes[0].professional_metadata_attributes.assets_attributes[0].asset_type',
        'curriculum',
      ));
    }
  }

  requiredFieldLabel(assetField, label) {
    let fieldRequired = '';
    if (assetField !== undefined && assetField.required && label.props.id === 'jobs.attach_cv') {
      fieldRequired = '*';
    }
    return (
      <span>
        {fieldRequired}
        {label}
      </span>
    );
  }

  requiredFile(assetField, index) {
    let validations = [];
    if (assetField.required) {
      validations = (index === 0) ? [required, isAsset] : [required];
    } else {
      validations = (index === 0) ? [isAsset] : [];
    }
    return validations;
  }

  addFields() {
    const { fields, formName, dispatch } = this.props;
    fields.push();
    const index = fields.length;
    dispatch(change(
      formName,
      `user.user_representations_attributes[0].professional_metadata_attributes.assets_attributes[${index}].asset_type`,
      'other',
    ));
  }

  render() {
    const {
      fields,
    } = this.props;

    const {
      assetField,
    } = this.props;

    return (
      <div className="block-input">
        {fields.map((field, index) => {
          const key = field + index;
          const label = (index === 0) ?
            <FormattedMessage id="jobs.attach_cv" />
            : <FormattedMessage id="jobs.attach" />;
          const validations = this.requiredFile(assetField, index);

          return (
            <div key={key} className="cv-wrapper-cnt">
              <div className="cv-wrapper">
                <Field
                  className="block-input"
                  component={RenderFileField}
                  label={this.requiredFieldLabel(assetField, label)}
                  name={`${field}.file`}
                  validate={validations}
                />
              </div>
              { index !== 0 &&
                <button type="button" onClick={() => fields.remove(index)}>
                  <Icon name="huge-cancel-circle" />
                </button>}
            </div>
          );
        })}
        <span className="add-fields link" onClick={() => fields.length < 5 && this.addFields()}>
          <FormattedMessage id="jobs.add_file" />
        </span>
      </div>
    );
  }
}

RenderAttachFileRegistrationForm.propTypes = propTypes;

export default connect()(RenderAttachFileRegistrationForm);
