import React from 'react';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import Icon from 'common-components/Icon';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import markToDestroy from 'utils/form/mark-to-destroy';
import RenderSelectField from 'common-components/form/RenderSelectField';

const propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  languageList: PropTypes.arrayOf(PropTypes.string).isRequired,
  languageLevels: PropTypes.objectOf(PropTypes.number).isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
};

class ProfessionalLanguages extends React.Component {
  constructor(props) {
    super(props);

    this.languageList = this.languageList.bind(this);
    this.languageLevels = this.languageLevels.bind(this);
  }

  languageList() {
    const { languageList } = this.props;

    return languageList.map((language) => ({ value: language, label: language }));
  }

  languageLevels() {
    const { languageLevels } = this.props;

    return Object.keys(languageLevels).map((language) => (
      { value: languageLevels[language], label: language }));
  }

  render() {
    const {
      fields, fieldLabel, fieldValidation,
      systemFieldsForm: {
        language: languageField,
      },
      intl: { messages },
    } = this.props;

    if (languageField === undefined) return null;

    return (
      <div>
        <div className="title">{messages['jobs.languages']}</div>
        {fields.map((field, index) => {
          if (fields.get(index)._destroy) return null;
          const key = field + index;
          const language = this.languageList().filter(
            (e) => e.value === fields.get(index).language,
          );
          const languageId = fields.get(index).id;

          return (
            <div key={key} className="block">
              <div className="row form-group">
                <div className="col-6" data-input-name={`${fields.name}[${index}].language`} data-label-text={messages['jobs.language']}>
                  <Field
                    className="block-input"
                    component={RenderSelectField}
                    label={fieldLabel(languageField, messages['jobs.language'])}
                    name={`${field}.language`}
                    options={this.languageList()}
                    validate={fieldValidation(languageField)}
                    placeholder={`${messages['jobs.language']}`}
                  />
                  {
                    language.length === 0 && languageId &&
                      <div className="block-input">
                        <span className="label" />
                        <span className="error">
                          {messages['jobs.warning_message_language']}
                        </span>
                      </div>
                  }
                </div>

                <div className="col-6" data-input-name={`${fields.name}[${index}].level`} data-label-text={messages['jobs.level']}>
                  <Field
                    className="block-input"
                    component={RenderSelectField}
                    label={fieldLabel(languageField, messages['jobs.level'])}
                    name={`${field}.level`}
                    options={this.languageLevels()}
                    validate={fieldValidation(languageField)}
                    placeholder={`${messages['jobs.level']}`}
                  />
                </div>
              </div>

              <div className="row form-group">
                <div className="link float-right" onClick={() => markToDestroy(index, fields)}>{messages['jobs.delete_language']}</div>
              </div>
            </div>
          );
        })}
        <div className="add link link-plus" onClick={() => fields.push({})}>
          <Icon name="huge-add-circle" />
          {messages['jobs.add_language']}
        </div>
      </div>
    );
  }
}

ProfessionalLanguages.propTypes = propTypes;
ProfessionalLanguages.defaultProps = defaultProps;

export default injectIntl(ProfessionalLanguages);
