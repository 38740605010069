import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import CustomFieldsArrayFields from './CustomFieldsArrayFields';

const propTypes = {
  style: PropTypes.shape({
    color: PropTypes.string,
  }),
  customFieldsForm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const defaultProps = {
  style: {},
};

class CustomFields extends React.Component {
  constructor(props) {
    super(props);

    this.renderCustomFieldGroupLabel = this.renderCustomFieldGroupLabel.bind(this);
    this.defineCustomFieldLabel = this.defineCustomFieldLabel.bind(this);
  }

  defineCustomFieldLabel(customFieldGroup) {
    if (customFieldGroup.type === 'CustomField::Groups::SimpleType') {
      return customFieldGroup.fields[0].label;
    }
    return customFieldGroup.groupCode;
  }

  renderCustomFieldGroupLabel(customFieldGroup) {
    const { style } = this.props;

    if (customFieldGroup.type === 'CustomField::Groups::SimpleType') {
      return '';
    }

    return (
      <div className="title" style={style}>
        { customFieldGroup.label || customFieldGroup.groupCode }
      </div>
    );
  }

  render() {
    const {
      customFieldsForm,
    } = this.props;

    return (
      <div>
        {customFieldsForm.map((customFieldGroup) => (
          <div key={`group_${customFieldGroup.id}`}>
            { this.renderCustomFieldGroupLabel(customFieldGroup) }
            <FieldArray
              component={CustomFieldsArrayFields}
              repeteable={customFieldGroup.repeteable}
              name={`responses.${customFieldGroup.id.toString()}`}
              customFields={customFieldGroup.fields}
              label={this.defineCustomFieldLabel(customFieldGroup)}
            />
          </div>
        ))}
      </div>
    );
  }
}

CustomFields.propTypes = propTypes;
CustomFields.defaultProps = defaultProps;

export default CustomFields;
