import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { change } from 'redux-form';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  renderField: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string,
  employments: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
  name: null,
  employments: null,
};

class Employments extends React.Component {
  constructor(props) {
    super(props);

    this.activeEmployee = this.activeEmployee.bind(this);

    this.state = {
      employee: !(props.employments === undefined || props.employments === null),
    };
  }

  activeEmployee() {
    const {
      companyId, formName, dispatch, name,
    } = this.props;
    const { employee } = this.state;

    if (employee) {
      dispatch(change(formName, name, null));
      this.setState({ employee: false });
    } else {
      dispatch(change(formName, `${name}.company_id`, companyId));
      this.setState({ employee: true });
    }
  }

  render() {
    const {
      fieldLabel, fieldValidation, renderField, intl: { messages },
      systemFieldsForm: {
        employment_ref: employmentField,
      }, name,
    } = this.props;
    const { employee } = this.state;

    if (employmentField === undefined) return null;

    return (
      <div>
        <div className="employee-check block-input input_checkbox">
          <input type="checkbox" id="employee-check-id" onChange={this.activeEmployee} checked={employee} />
          <label htmlFor="employee-check-id">{ messages['jobs.registration_settings.employment.im_employee'] }</label>
        </div>

        {
          employee &&
            renderField(
              fieldLabel(
                employmentField,
                messages['jobs.registration_settings.employment.employment_ref'],
              ),
              `${name}.ref`,
              fieldValidation(employmentField),
              'jobs.registration_settings.employment.employment_ref',
            )
        }
      </div>
    );
  }
}

Employments.propTypes = propTypes;
Employments.defaultProps = defaultProps;

export default connect()(injectIntl(Employments));
