import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { change } from 'redux-form';
import { firewallUnsupportedCharacters } from 'utils/form/validations';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  renderField: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  employments: PropTypes.arrayOf(PropTypes.shape({})),
  companyId: PropTypes.number.isRequired,
  formName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
};

const defaultProps = {
  systemFieldsForm: [],
  employments: null,
};

class Employments extends React.Component {
  constructor(props) {
    super(props);

    this.activeEmployee = this.activeEmployee.bind(this);

    this.state = {
      employee: !(props.employments === undefined || props.employments === null),
    };
  }

  activeEmployee() {
    const {
      companyId, formName, dispatch,
    } = this.props;

    const { employee } = this.state;

    if (employee) {
      dispatch(change(formName, 'user.employments_attributes[0]', null));
      this.setState({ employee: false });
    } else {
      dispatch(change(formName, 'user.employments_attributes[0].company_id', companyId));
      this.setState({ employee: true });
    }
  }

  render() {
    const {
      companyId,
      fieldLabel, fieldValidation, renderField,
      systemFieldsForm: {
        employment_ref: employmentField,
      },
      intl: { messages },
    } = this.props;

    const { employee } = this.state;

    if (employmentField === undefined) return null;

    return (
      <React.Fragment>
        <div className="employee-check form-check input_checkbox form-group">
          <input type="checkbox" id={`employee-${companyId}`} onChange={this.activeEmployee} checked={employee} />
          <label className="box" htmlFor={`employee-${companyId}`}>{ messages['jobs.registration_settings.employment.im_employee'] }</label>
        </div>

        {
          employee &&
            <div className="row form-group">
              <div className="col-6">
                {
                  renderField(
                    fieldLabel(
                      employmentField,
                      messages['jobs.registration_settings.employment.employment_ref'],
                    ),
                    'ref',
                    fieldValidation(employmentField, firewallUnsupportedCharacters),
                    messages['jobs.registration_settings.employment.employment_ref'],
                  )
                }
              </div>
            </div>
        }
      </React.Fragment>
    );
  }
}

Employments.propTypes = propTypes;
Employments.defaultProps = defaultProps;

export default connect()(injectIntl(Employments));
