import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { url, skypeUsername } from 'utils/form/validations';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  renderField: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
};

function SocialMetadata(props) {
  const {
    fieldLabel, fieldValidation, renderField,
    systemFieldsForm: {
      linkedin: linkedinField,
      twitter: twitterField,
      facebook: facebookField,
      google: googleField,
      skype: skypeField,
      other: otherField,
    },
    intl: { messages },
  } = props;

  return (
    <React.Fragment>
      {
        linkedinField && (
          <div className="row form-group">
            <div className="col-12">
              {
                renderField(
                  fieldLabel(
                    linkedinField,
                    messages['jobs.social_metadata.linkedin'],
                  ),
                  'linkedin',
                  fieldValidation(linkedinField, url),
                  messages['jobs.social_metadata.linkedin'],
                  'user.user_representations_attributes[0].social_metadata_attributes.linkedin',
                )
              }
            </div>
          </div>
        )
      }
      {
        twitterField && (
          <div className="row form-group">
            <div className="col-12">
              {
                renderField(
                  fieldLabel(
                    twitterField,
                    messages['jobs.social_metadata.twitter'],
                  ),
                  'twitter',
                  fieldValidation(twitterField, url),
                  messages['jobs.social_metadata.twitter'],
                  'user.user_representations_attributes[0].social_metadata_attributes.twitter',
                )
              }
            </div>
          </div>
        )
      }
      {
        facebookField && (
          <div className="row form-group">
            <div className="col-12">
              {
                renderField(
                  fieldLabel(
                    facebookField,
                    messages['jobs.social_metadata.facebook'],
                  ),
                  'facebook',
                  fieldValidation(facebookField, url),
                  messages['jobs.social_metadata.facebook'],
                  'user.user_representations_attributes[0].social_metadata_attributes.facebook',
                )
              }
            </div>
          </div>
        )
      }
      {
        googleField && (
          <div className="row form-group">
            <div className="col-12">
              {
                renderField(
                  fieldLabel(
                    googleField,
                    messages['jobs.social_metadata.google'],
                  ),
                  'google',
                  fieldValidation(googleField, url),
                  messages['jobs.social_metadata.google'],
                  'user.user_representations_attributes[0].social_metadata_attributes.google',
                )
              }
            </div>
          </div>
        )
      }
      {
        skypeField && (
          <div className="row form-group">
            <div className="col-12">
              {
                renderField(
                  fieldLabel(
                    skypeField,
                    messages['jobs.social_metadata.skype'],
                  ),
                  'skype',
                  fieldValidation(skypeField, skypeUsername),
                  messages['jobs.social_metadata.skype'],
                  'user.user_representations_attributes[0].social_metadata_attributes.skype',
                )
              }
            </div>
          </div>
        )
      }
      {
        otherField && (
          <div className="row form-group">
            <div className="col-12">
              {
                renderField(
                  fieldLabel(
                    otherField,
                    messages['jobs.social_metadata.other'],
                  ),
                  'other',
                  fieldValidation(otherField, url),
                  messages['jobs.social_metadata.other'],
                  'user.user_representations_attributes[0].social_metadata_attributes.other',
                )
              }
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
}

SocialMetadata.propTypes = propTypes;
SocialMetadata.defaultProps = defaultProps;

export default injectIntl(SocialMetadata);
