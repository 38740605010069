import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { injectIntl } from 'react-intl';

import RenderField from 'common-components/form/RenderField';
import RenderSelectField from 'common-components/form/RenderSelectField';
import RenderCheckboxFields from 'common-components/form/RenderCheckboxFields';
import RenderRadioFields from 'common-components/form/RenderRadioFields';
import { required, firewallUnsupportedCharacters } from 'utils/form/validations';

const propTypes = {
  companyQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  questionTypes: PropTypes.shape({
    checkbox: PropTypes.number,
    radio: PropTypes.number,
    select: PropTypes.number,
    text: PropTypes.number,
  }).isRequired,
};

const defaultProps = {
  companyQuestions: [],
};

class CompanyQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.responses = this.responses.bind(this);
    this.renderSelectQuestion = this.renderSelectQuestion.bind(this);
    this.renderCheckboxQuestion = this.renderCheckboxQuestion.bind(this);
    this.renderRadioQuestion = this.renderRadioQuestion.bind(this);
    this.renderTextQuestion = this.renderTextQuestion.bind(this);
  }

  responses(question) {
    const answers = question.raw_answers;

    return answers.map((answer) => ({ value: answer.id, label: answer.title }));
  }

  renderSelectQuestion(index, question, className, label, validations) {
    return (
      <div
        key={question.id}
        data-input-name={`responses_attributes[${index}].response`}
        data-label-text={question.title}
      >
        <Field
          className={className}
          component={RenderSelectField}
          label={label}
          name={`responses_attributes[${index}].response`}
          options={this.responses(question)}
          placeholder=""
          validate={validations}
        />
      </div>
    );
  }

  renderCheckboxQuestion(index, question, className, label, validations) {
    return (
      <div
        key={question.id}
        data-input-name={`responses_attributes[${index}].responses`}
        data-label-text={question.title}
      >
        <Field
          className={className}
          component={RenderCheckboxFields}
          label={label}
          name={`responses_attributes[${index}].responses`}
          options={this.responses(question)}
          validate={validations}
        />
      </div>
    );
  }

  renderRadioQuestion(index, question, className, label, validations) {
    return (
      <div
        key={question.id}
        data-input-name={`responses_attributes[${index}].response`}
        data-label-text={question.title}
      >
        <Field
          className={className}
          component={RenderRadioFields}
          label={label}
          name={`responses_attributes[${index}].response`}
          options={this.responses(question)}
          validate={validations}
        />
      </div>
    );
  }

  renderTextQuestion(index, question, className, label, validations) {
    return (
      <div
        key={question.id}
        data-input-name={`responses_attributes[${index}].response`}
        data-label-text={question.title}
      >
        <Field
          className={className}
          component={RenderField}
          label={label}
          name={`responses_attributes[${index}].response`}
          validate={validations}
        />
      </div>
    );
  }

  render() {
    const {
      companyQuestions,
      questionTypes,
      intl: { messages },
    } = this.props;

    if (companyQuestions && companyQuestions.length < 1) return null;

    return (
      <div>
        <div className="title">{messages['jobs.questions'] }</div>
        {
          companyQuestions.map((question, index) => {
            const className = 'form-group';
            const label = question.mandatory
              ? (
                <span className="label" title={question.title}>
                  {question.title}
                  <span className="require">*</span>
                </span>
              )
              : question.title;
            const validations = question.mandatory ? [required] : undefined;

            if (question.question_type === questionTypes.select) {
              return this.renderSelectQuestion(
                index,
                question,
                className,
                label,
                validations,
              );
            } if (question.question_type === questionTypes.checkbox) {
              return this.renderCheckboxQuestion(
                index,
                question,
                className,
                label,
                validations,
              );
            } if (question.question_type === questionTypes.radio) {
              return this.renderRadioQuestion(
                index,
                question,
                className,
                label,
                validations,
              );
            }
            return this.renderTextQuestion(
              index,
              question,
              className,
              label,
              validations ? validations.concat(firewallUnsupportedCharacters) : firewallUnsupportedCharacters,
            );
          })
        }
      </div>
    );
  }
}

CompanyQuestions.propTypes = propTypes;
CompanyQuestions.defaultProps = defaultProps;

export default injectIntl(CompanyQuestions);
