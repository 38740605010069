import React from 'react';
import { FormSection, Field, FieldArray, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import $ from 'jquery';

import Modal from 'react-modal';
import RenderField from 'common-components/form/RenderField';
import RenderCheckboxField from 'common-components/form/RenderCheckboxField';
import { buildBody, buildHeaders } from 'utils/api/builder';
import { get } from 'utils/api/fetch-client';
import { required } from 'utils/form/validations';
import PersonalInfo from './PersonalInfo';
import Employments from './Employments';
import Location from './Location';
import JobPreference from './JobPreference';
import SocialMetadata from './SocialMetadata';
import ProfessionalExperiences from './ProfessionalExperiences';
import ProfessionalEducations from './ProfessionalEducations';
import ComplementaryEducations from './ComplementaryEducations';
import ProfessionalLanguages from './ProfessionalLanguages';
import CompanyQuestions from './CompanyQuestions';
import AvailabilityDates from './AvailabilityDates';
import CustomFields from '../../custom/headhunter-jobs/CustomFields';
import ModalLogin from './ModalLogin';
import ExistingUser from './ExistingUser';
import RenderFormErrors from '../../custom/headhunter-jobs/RenderFormErrors';

const formName = 'registration';

const propTypes = {
  companyQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  formData: PropTypes.shape({
    company: PropTypes.shape(),
    job: PropTypes.shape(),
  }).isRequired,
  company: PropTypes.shape({
    company_id: PropTypes.number,
    companyColor: PropTypes.string,
  }).isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  companyQuestionTypes: PropTypes.shape({}),
  formType: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  job: PropTypes.shape({
    company_id: PropTypes.number,
    id: PropTypes.number,
  }),
  legalMessage: PropTypes.string,
  legalInfoText: PropTypes.string,
  questionTypes: PropTypes.shape({
    checkbox: PropTypes.number,
    radio: PropTypes.number,
    select: PropTypes.number,
    text: PropTypes.number,
  }),
  submitting: PropTypes.bool.isRequired,
  languageList: PropTypes.arrayOf(PropTypes.string).isRequired,
  languageLevels: PropTypes.objectOf(PropTypes.number).isRequired,
  customFieldsForm: PropTypes.arrayOf(PropTypes.shape({})),
  idDocumentFieldsForm: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
    })),
  }),
  candidateBelongsToCompany: PropTypes.bool.isRequired,
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const defaultProps = {
  systemFieldsForm: [],
  companyQuestions: [],
  legalMessage: null,
  legalInfoText: null,
  questionTypes: {
    checkbox: 2,
    radio: 3,
    select: 1,
    text: 4,
  },
  job: undefined,
  customFieldsForm: undefined,
  idDocumentFieldsForm: {
    fields: [],
  },
  companyQuestionTypes: undefined,
};

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.companyQuestionsPresenter = this.companyQuestionsPresenter.bind(this);
    this.fieldLabel = this.fieldLabel.bind(this);
    this.fieldValidation = this.fieldValidation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderField = this.renderField.bind(this);
    this.renderArrayFields = this.renderArrayFields.bind(this);
    this.candidateLogin = this.candidateLogin.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.closeLoginModal = this.closeLoginModal.bind(this);
    this.validExistingEmail = this.validExistingEmail.bind(this);
    this.idDocumentFieldValue = this.idDocumentFieldValue.bind(this);

    this.state = {
      showLoginModal: false,
      showExistingEmail: false,
      showExistingCandidacy: false,
      canBeLoginSubmitted: true,
      formErrors: null,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      customFieldsForm,
    } = this.props;

    const candidateResponses = {};
    for (const customField of customFieldsForm) {
      candidateResponses[customField.id] = [];
      const fieldAnswers = customField.fields.map((field) => {
        let response;
        if (field.type === 'CustomField::Fields::MultiSelectType') {
          response = { options: [] };
        } else if (field.type === 'CustomField::Fields::SelectType' ||
        field.type === 'CustomField::Fields::RadioType') {
          response = { option_id: '' };
        } else if (field.type === 'CustomField::Fields::RangeType') {
          response = { range: [field.limitMin, field.limitMax] };
        } else if (field.type === 'CustomField::Fields::FileType') {
          response = { asset: {} };
        } else {
          response = { response: '' };
        }
        return { [field.fieldCode]: response };
      });
      candidateResponses[customField.id] = fieldAnswers;
    }

    const initData = {
      job_id: this.props.formData.job.id,
      user: {
        user_representations_attributes: [
          {
            user_metadata_attributes: {
              dni_type: this.idDocumentFieldValue(),
            },
          },
        ],
      },
      custom_field_groups: {
        responses: candidateResponses,
      },
      responses_attributes: this.companyQuestionsPresenter(),
    };

    this.props.initialize(initData);
  }

  handleSubmit(values) {
    const url = Routes.candidates_path();

    this.setState({ formErrors: null });
    $('#loader-wrapper').parent().show();

    $.ajax({
      url,
      type: 'POST',
      beforeSend: xhr => xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')),
      data: buildBody(values),
      contentType: false,
      processData: false,
      success: () => {
        $('#loader-wrapper').parent().hide();
      },
      error: (data) => {
        const dataProcessed = data;
        if (dataProcessed.status === 403) {
          const error = <FormattedMessage id="shared.form.errors.forbidden_firewall" />;
          dataProcessed['responseJSON'] = { errors: [error] };
        }
        this.setState({ formErrors: dataProcessed.responseJSON.errors });
        $('#loader-wrapper').parent().hide();
        document.querySelector('.send-cv-module').scrollIntoView({ behavior: 'smooth' });
      },
    });
  }

  companyQuestionsPresenter() {
    return this.props.companyQuestions.map((question) => (
      { id: question.id, question_type: question.question_type }
    ));
  }

  idDocumentFieldValue() {
    const { fields } = this.props.idDocumentFieldsForm;
    if (fields !== undefined && fields.length > 0) {
      return fields[0].value;
    }

    return undefined;
  }

  fieldLabel(field, label) {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return field.required ? <span>*{label}</span> : label;
  }

  fieldValidation(field, extraValidations = null) {
    const validations = extraValidations ? [required].concat(extraValidations) : required;

    return field.required ? validations : extraValidations;
  }

  openLoginModal() {
    this.setState({ showLoginModal: true });
  }

  closeLoginModal() {
    this.setState({ showLoginModal: false });
  }

  candidateLogin(values) {
    const {
      company: {
        company_id: companyId,
      },
    } = this.props;

    const url = Routes.company_create_candidate_access_session_path(companyId);
    this.setState({ canBeLoginSubmitted: false });

    $.ajax({
      type: 'POST',
      url,
      data: values,
      headers: buildHeaders(),
      success: (response) => {
        if (response !== undefined && response !== null && response.authorized) {
          window.location.reload();
        } else {
          this.setState({ loginError: true, canBeLoginSubmitted: true });
        }
      },
      error: () => {
        this.setState({ loginError: true, canBeLoginSubmitted: true });
      },
    });
  }

  validExistingEmail(_event, newValue) {
    const {
      job: {
        id,
        company_id: companyId,
      },
    } = this.props.formData;

    if (newValue) {
      const url = Routes.job_center_candidates_path({
        company_id: companyId,
        candidate: { email: newValue, job_id: id },
      });

      get(url).then((data) => {
        const {
          existing_candidate: showExistingEmail,
          applied_to_job: showExistingCandidacy,
        } = data;

        this.setState({ showExistingEmail, showExistingCandidacy });
      });
    }
  }

  renderField(label, name, validate, placeholder, type = 'text') {
    const {
      intl: { messages },
    } = this.props;

    return (
      <Field
        className="block-input"
        component={RenderField}
        label={label}
        name={name}
        type={type}
        validate={validate}
        placeholder={messages[placeholder]}
      />
    );
  }

  renderArrayFields(component, name, options = {}) {
    const { systemFieldsForm } = this.props;

    return (
      <FieldArray
        component={component}
        fieldLabel={this.fieldLabel}
        fieldValidation={this.fieldValidation}
        formName={formName}
        name={name}
        systemFieldsForm={systemFieldsForm}
        renderField={this.renderField}
        {...options}
      />
    );
  }

  render() {
    const {
      showExistingEmail,
      showExistingCandidacy,
      formErrors,
    } = this.state;

    const {
      handleSubmit,
      submitting,
      systemFieldsForm,
      customFieldsForm,
      idDocumentFieldsForm,
      legalMessage,
      companyQuestions,
      companyQuestionTypes,
      company: {
        companyColor,
      },
      formData: {
        job: {
          anonymous: anonymousJob,
        },
      },
      company: {
        company_id: companyId,
      },
      intl: { messages },
      genders,
    } = this.props;

    const rgpdLabelText = (
      <span
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: legalMessage,
        }}
      />
    );
    const rgpdLabel = (
      <div className="register-message">
        <div className="text">
          {rgpdLabelText}
        </div>
        <span className="require">*</span>
      </div>
    );

    const styleButtons = {
      backgroundColor: companyColor,
      border: `${companyColor} solid 1px`,
    };
    const titleStyle = { color: companyColor };
    const style = { };

    return (
      <div>
        {
          !anonymousJob &&
            (
              <Modal
                overlayClassName="overlay-modal-style"
                className="modal-style share-modal login-share-modal"
                isOpen={this.state.showLoginModal}
                contentLabel="ShareJob"
                onRequestClose={this.closeLoginModal}
                ariaHideApp={false}
              >
                <ModalLogin
                  onSubmit={this.candidateLogin}
                  styleButtons={styleButtons}
                  loginError={this.state.loginError}
                  forgotPasswordUrl={Routes.company_new_candidate_password_recover_path(companyId)}
                  canBeLoginSubmitted={this.state.canBeLoginSubmitted}
                />
              </Modal>
            )
        }
        <RenderFormErrors
          formErrors={formErrors}
        />
        {
          !anonymousJob && showExistingEmail &&
            (
              <ExistingUser
                showExistingCandidacy={showExistingCandidacy}
                showExistingEmail={showExistingEmail}
                openLoginModal={this.openLoginModal}
              />
            )
        }
        {
          this.props.formType !== 'closed-offer' &&
            (
              <div className="title" style={titleStyle}>
                <FormattedMessage id="jobs.apply" />
              </div>
            )
        }
        <div className="form">
          <div className="form-description">
            <FormattedMessage id="jobs.jobcenter_message" />
          </div>

          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div>
              <FormSection name="user">
                <PersonalInfo
                  fieldLabel={this.fieldLabel}
                  fieldValidation={this.fieldValidation}
                  formName={formName}
                  systemFieldsForm={systemFieldsForm}
                  idDocumentFieldsForm={idDocumentFieldsForm}
                  renderField={this.renderField}
                  validExistingEmail={anonymousJob ? undefined : this.validExistingEmail}
                  showExistingEmail={showExistingEmail}
                  showExistingCandidacy={showExistingCandidacy}
                  genders={genders}
                />
              </FormSection>
              <Employments
                companyId={this.props.formData.job.company_id}
                fieldLabel={this.fieldLabel}
                fieldValidation={this.fieldValidation}
                formName={formName}
                systemFieldsForm={systemFieldsForm}
                name="user.employments_attributes[0]"
                renderField={this.renderField}
              />
              <FormSection name="user.user_representations_attributes[0]">
                <FormSection name="user_location_attributes">
                  <Location
                    countries={this.props.countries}
                    fieldLabel={this.fieldLabel}
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    renderField={this.renderField}
                  />
                </FormSection>
                <FormSection name="job_preference_attributes">
                  <JobPreference
                    fieldLabel={this.fieldLabel}
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    renderField={this.renderField}
                  />
                </FormSection>
                <FormSection name="professional_metadata_attributes">
                  <AvailabilityDates
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    messages={messages}
                  />
                </FormSection>
                <FormSection name="social_metadata_attributes">
                  <SocialMetadata
                    fieldLabel={this.fieldLabel}
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    renderField={this.renderField}
                  />
                </FormSection>
                <FormSection name="professional_metadata_attributes">
                  {
                    this.renderArrayFields(
                      ProfessionalExperiences,
                      'professional_experiences_attributes',
                    )
                  }
                  {
                    this.renderArrayFields(
                      ProfessionalEducations,
                      'professional_educations_attributes',
                    )
                  }
                  {
                    this.renderArrayFields(
                      ComplementaryEducations,
                      'complementary_educations_attributes',
                    )
                  }
                  {
                    this.renderArrayFields(
                      ProfessionalLanguages,
                      'professional_languages_attributes',
                      {
                        languageLevels: this.props.languageLevels,
                        languageList: this.props.languageList,
                      },
                    )
                  }
                </FormSection>
              </FormSection>
              { customFieldsForm.length > 0 &&
                (
                  <FormSection name="custom_field_groups">
                    <CustomFields
                      customFieldsForm={customFieldsForm}
                      style={style}
                    />
                  </FormSection>
                )}
            </div>

            <CompanyQuestions
              questionTypes={companyQuestionTypes}
              companyQuestions={companyQuestions}
              formName={formName}
            />

            <div className="conditions-job-center">
              <div className="conditions-wrapper">
                <Field
                  className="block-terms-and-conditions block-input form-check"
                  component={RenderCheckboxField}
                  label={rgpdLabel}
                  name="terms_and_conditions"
                  validate={required}
                />
              </div>
              <div className="register-btn">
                <button
                  className="employment-center-register-btn"
                  type="submit"
                  disabled={submitting || showExistingCandidacy}
                >
                  <FormattedMessage id="jobs.send_cv" />
                </button>
              </div>
              <div
                className="conditions-rgpd"
                dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                  __html: this.props.legalInfoText,
                }}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

RegistrationForm.propTypes = propTypes;
RegistrationForm.defaultProps = defaultProps;

export default reduxForm({ form: formName })(injectIntl(RegistrationForm));
