/* eslint react/no-danger: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import HighlighterKeywords from '../../bundles/companies/components/common/HighlighterKeywords';

const propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  wrapperTag: PropTypes.string,
  newLineFormat: PropTypes.bool,
  highlighter: PropTypes.bool,
};

const defaultProps = {
  text: null,
  tooltip: null,
  wrapperTag: 'div',
  newLineFormat: true,
  highlighter: false,
};

function convertToSimpleFormat(text, newLineFormat) {
  const crlf = /\r\n?/g;
  const newLinesTogether = /\n\n+/g;
  const newLine = /([^\n]\n)(?=[^\n])/g;

  let str = text.trim();
  str = str.replace(crlf, '\n');
  if (newLineFormat) str = str.replace(newLinesTogether, '</p><p>');
  if (newLineFormat) str = str.replace(newLine, '$1<br/>');
  if (str.includes('</p><p>')) str = `<p>${str}</p>`;

  return str;
}

function SimpleFormat({
  text, wrapperTag, tooltip, highlighter, newLineFormat, ...rest
}) {
  if (!(typeof text === 'string')) return null;
  const t = text.replace(/&nbsp;/gi, ' ');

  if (highlighter) {
    return (
      <div title={t}>
        <HighlighterKeywords text={convertToSimpleFormat(t, newLineFormat)} />
      </div>
    );
  }

  if (tooltip == null) {
    return React.createElement(wrapperTag, {
      dangerouslySetInnerHTML: { __html: convertToSimpleFormat(t, newLineFormat) },
      title: t,
      ...rest,
    });
  }

  return React.createElement(wrapperTag, {
    dangerouslySetInnerHTML: { __html: convertToSimpleFormat(t, newLineFormat) },
    text: t,
    ...rest,
  });
}

SimpleFormat.propTypes = propTypes;
SimpleFormat.defaultProps = defaultProps;

export default SimpleFormat;
