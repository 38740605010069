// Function to add the "_destroy" parameter that ActiveRecord uses to eliminate a relationship
// when we use FieldArray

function addNewCustomFields(fields, customField) {
  const countFields = fields.length;
  let deletingFields = 1;

  fields.forEach((_, index) => {
    const answer = fields.get(index);
    if (customField !== null && customField !== undefined &&
      answer !== undefined && answer[customField.fieldCode] &&
      answer[customField.fieldCode]._destroy) {
      deletingFields += 1;
    }
  });

  return deletingFields === countFields;
}

const markToDestroyCustomField = (index, fields, response, addBlankField = false) => {
  const {
    get,
    insert,
    push,
    remove,
  } = fields;

  let changeResponse = false;
  const value = {};

  if (response !== null && response !== undefined && get(index) !== undefined) {
    value[response.fieldCode] = { ...get(index)[response.fieldCode], _destroy: true };
    changeResponse = true;
  }
  remove(index);

  if (changeResponse && value[response.fieldCode].id) setTimeout(() => insert(index, value), 1);

  if (addBlankField && addNewCustomFields(fields, response)) {
    push();
  }
};

export default markToDestroyCustomField;
