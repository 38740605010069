import React from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  checked: PropTypes.bool,
  hint: PropTypes.node,
};

const defaultProps = {
  className: null,
  label: null,
  checked: false,
  hint: null,
};

function RenderCheckboxField(props) {
  const {
    input, meta, checked,
    label, className, hint,
    ...rest
  } = props;

  return (
    <div className={className}>
      <div className={`inner-row ${className}`}>
        <input id={input.name} type="checkbox" {...input} {...rest} value={input.value !== '' ? input.value : checked} checked={input.value !== '' ? input.value : checked} />
        <Label label={label} name={input.name} className="field-checkbox-container" />
        {hint && <span className="hint">{hint}</span>}
      </div>
      <ErrorMessage {...meta} />
    </div>
  );
}

RenderCheckboxField.propTypes = propTypes;
RenderCheckboxField.defaultProps = defaultProps;

export default RenderCheckboxField;
