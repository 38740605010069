import React from 'react';
import PropTypes from 'prop-types';

import { youtubeUrlMatching, vimeoUrlMatching } from 'utils/helpers';

const propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

class VideoIframe extends React.Component {
  renderYoutubeIframe(match) {
    return (
      <iframe title="YouTube video player" src={`https://www.youtube.com/embed/${match[1]}?rel=0&autoplay=0&controls=1`} width="583" height="322" frameBorder="0" allowFullScreen />
    );
  }

  renderVimeoIframe(match) {
    return (
      <iframe title="Vimeo video player" src={`https://player.vimeo.com/video/${match[1]}`} width="583" height="322" frameBorder="0" allowFullScreen />
    );
  }

  render() {
    const { videoUrl } = this.props;
    const youtubeUrl = youtubeUrlMatching(videoUrl);
    const vimeoUrl = !youtubeUrl && vimeoUrlMatching(videoUrl);
    return (
      <React.Fragment>
        { youtubeUrl &&
          this.renderYoutubeIframe(youtubeUrl)}
        {vimeoUrl &&
          this.renderVimeoIframe(vimeoUrl)}
      </React.Fragment>
    );
  }
}

VideoIframe.propTypes = propTypes;

export default VideoIframe;
