import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Icon from 'common-components/Icon';
import PropTypes from 'prop-types';

const propTypes = {
  showExistingCandidacy: PropTypes.bool.isRequired,
  showExistingEmail: PropTypes.bool.isRequired,
};

class ExistingUser extends React.Component {
  constructor(props) {
    super(props);

    this.hideAlert = this.hideAlert.bind(this);

    this.state = {
      showAlert: true,
    };
  }

  hideAlert() {
    this.setState({ showAlert: false });
  }

  render() {
    const {
      showExistingCandidacy, showExistingEmail,
    } = this.props;

    const { showAlert } = this.state;

    return (
      <div>
        { showAlert && showExistingCandidacy && (
          <div className="alert danger no-fading full">
            <Icon name="huge-alert-circle" klass="danger msg-icon" />
            <button type="button" onClick={this.hideAlert} className="close">
              <Icon name="huge-cancel-01" />
            </button>
            <div className="message">
              <h3 className="mb-small not-base">
                <FormattedMessage id="jobs.job_centers.existing_candidacy.title" />
              </h3>
              <p>
                <FormattedMessage id="jobs.job_centers.existing_candidacy.first_description" />
                <span
                  className="link"
                  data-modal-content=""
                  data-target="#login-modal"
                  onClick={(event) => event.preventDefault()}
                >
                  <FormattedMessage id="jobs.log_in" />
                </span>
                <FormattedMessage id="jobs.job_centers.existing_candidacy.second_description" />
              </p>
            </div>
          </div>
        )}

        { showAlert && !showExistingCandidacy && showExistingEmail && (
          <div className="alert info no-fading full">
            <Icon name="huge-alert-circle" klass="danger msg-icon" />
            <button type="button" onClick={this.hideAlert} className="close">
              <Icon name="huge-cancel-01" />
            </button>
            <div className="message">
              <h3 className="mb-small not-base">
                <FormattedMessage id="jobs.job_centers.existing_email.title" />
              </h3>
              <p>
                <FormattedMessage id="jobs.job_centers.existing_email.description" />
                <span
                  className="link"
                  data-modal-content=""
                  data-target="#login-modal"
                  onClick={(event) => event.preventDefault()}
                >
                  <FormattedMessage id="jobs.log_in" />
                </span>
                .
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ExistingUser.propTypes = propTypes;

export default injectIntl(ExistingUser);
