import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import Icon from 'common-components/Icon';
import view from 'job-centers/containers/View';
import RegistrationForm from './registration-form/RegistrationForm';
import RegistrationUserLoggedForm from './registration-form/RegistrationUserLoggedForm';

const propTypes = {
  appsignal_key: PropTypes.string,
  company: PropTypes.shape({
    companyOriginUrl: PropTypes.string.isRequired,
  }).isRequired,
  formData: PropTypes.shape({
    job: PropTypes.shape(),
  }).isRequired,
  appliedToJob: PropTypes.bool,
  formType: PropTypes.string.isRequired,
  candidateBelongsToCompany: PropTypes.bool.isRequired,
};

const defaultProps = {
  appsignal_key: '',
  appliedToJob: null,
};

class ShowContent extends React.Component {
  constructor(props) {
    super(props);

    this.renderForm = this.renderForm.bind(this);
  }

  renderForm() {
    const {
      company: { companyOriginUrl },
      formData: {
        job: {
          anonymous: anonymousJob,
        },
      }, formType, candidateBelongsToCompany,
      appliedToJob,
    } = this.props;

    if (appliedToJob) {
      return (
        <div className="alert success full">
          <Icon name="huge-tick-02" />
          <FormattedMessage id="jobs.applied_to_job" />
        </div>
      );
    } if (
      formType === 'user-already-registered' &&
      candidateBelongsToCompany) {
      return (
        <div className="alert success full">
          <Icon name="huge-tick-02" />
          <FormattedMessage id="jobs.inscribed_in_job" />
        </div>
      );
    } if (formType === 'closed-offer') {
      return (
        <div className="closed-offer">
          <p className="text">
            <FormattedMessage id="jobs.offer_closed" />
          </p>
          <button
            onClick={() => (document.location.href = companyOriginUrl)}
            className="button button--primary"
            type="button"
          >
            <FormattedMessage id="jobs.show_other_offers" />
          </button>
        </div>
      );
    } if (formType === 'logged-user-registration' &&
      candidateBelongsToCompany && !anonymousJob) {
      return (
        <RegistrationUserLoggedForm {...this.props} />
      );
    }
    return (
      <RegistrationForm {...this.props} />
    );
  }

  render() {
    const { appsignal_key: appsignalKey } = this.props;
    const appsignal = new Appsignal({
      key: appsignalKey,
    });
    return (
      <ErrorBoundary
        instance={appsignal}
      >
        <div className="send-cv-module pull-right">
          {this.renderForm()}
        </div>
      </ErrorBoundary>
    );
  }
}

ShowContent.propTypes = propTypes;
ShowContent.defaultProps = defaultProps;

export default view(ShowContent);
