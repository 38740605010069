import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';

import view from 'job-centers/containers/View';
import OfferList from './OfferList';

const propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape({})),
  settings: PropTypes.shape().isRequired,
  total_jobs: PropTypes.number,
  total_vacancies: PropTypes.number,
  paging_data: PropTypes.shape(),
  job_list_text: PropTypes.string,
  referrer_source: PropTypes.string,
  appsignal_key: PropTypes.string,
};

const defaultProps = {
  jobs: [],
  total_jobs: 0,
  total_vacancies: 0,
  paging_data: { page: 1, total: 1 },
  job_list_text: '',
  appsignal_key: '',
  referrer_source: null,
};

class IframeIndexContent extends React.Component {
  render() {
    const {
      jobs,
      settings,
      total_jobs: totalJobs,
      total_vacancies: totalVacancies,
      settings: {
        external_job_center_url: externalJobCenterUrl,
        show_total_jobs_iframe: showTotalJobsIframe,
        show_total_vacancies_iframe: showTotalVacanciesIframe,
        show_aggregation_iframe: showAggregationIframe,
        show_title_iframe: showTitleIframe,
      },
      paging_data: pagingData,
      job_list_text: jobListText,
      referrer_source: referrerSource,
      appsignal_key: appsignalKey,
    } = this.props;

    const careerSiteUrl = Routes.custom_companies_headhunter_jobs_path();
    const jobCenterUrl = externalJobCenterUrl || careerSiteUrl;
    const appsignal = new Appsignal({
      key: appsignalKey,
    });

    return (
      <ErrorBoundary
        instance={appsignal}
      >
        <div>
          { jobs.length === 0 ?
            <FormattedMessage id="jobs.empty_job" />
            : (
              <OfferList
                handleSubmit={this.handleSubmit}
                settings={settings}
                totalJobs={totalJobs}
                totalVacancies={totalVacancies}
                jobListText={jobListText}
                jobs={jobs}
                referrerSource={referrerSource}
                pagingData={pagingData}
                jobCenterUrl={jobCenterUrl}
                showTotalJobs={showTotalJobsIframe}
                showTotalVacancies={showTotalVacanciesIframe}
                showAggregation={showAggregationIframe}
                showTitle={showTitleIframe}
                fromPage
              />
            )}
        </div>
      </ErrorBoundary>
    );
  }
}

IframeIndexContent.propTypes = propTypes;
IframeIndexContent.defaultProps = defaultProps;

export default view(injectIntl(IframeIndexContent));
