import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { FormattedMessage } from 'react-intl';
import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  label: PropTypes.node,
  hint: PropTypes.node,
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  showToDestroyResponse: PropTypes.bool,
};

const defaultProps = {
  className: null,
  defaultValue: null,
  label: null,
  hint: null,
  showToDestroyResponse: true,
};

class RenderRangeField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleResetRange = this.handleResetRange.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.onSliderChangeComplete = this.onSliderChangeComplete.bind(this);
    this.state = {
      rangeValue: props.input.value.length ? props.input.value : undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { input: { value: v } } = this.props;
    const { input: { value: nv } } = nextProps;
    const { rangeValue } = this.state;
    if (JSON.stringify(v) !== JSON.stringify(nv) && JSON.stringify(nv) !== JSON.stringify(rangeValue)) {
      this.setState({ rangeValue: nv });
    }
  }

  handleResetRange() {
    const {
      input: { onChange }, max, min,
    } = this.props;

    this.setState({ rangeValue: [min, max] });
    onChange([min, max]);
  }

  onSliderChange(value) {
    this.setState({ rangeValue: value });
  }

  onSliderChangeComplete(value) {
    const {
      input: { onChange },
    } = this.props;
    onChange(value);
  }

  render() {
    const {
      meta, showToDestroyResponse, className, defaultValue, label, hint,
      min, max, ...rest
    } = this.props;
    const {
      rangeValue,
    } = this.state;
    let def = false;

    if (rangeValue !== undefined) def = rangeValue[0] === min && rangeValue[1] === max;

    return (
      <div className={className}>
        <div className="label-cnt">
          <Label label={label} />
          {hint && <span className="hint">{hint}</span>}
        </div>
        <div className="range-container">
          <Slider
            range
            min={min}
            max={max}
            value={rangeValue}
            defaultValue={rangeValue || defaultValue || [min, max]}
            onChange={val => this.onSliderChange(val)}
            onChangeComplete={val => this.onSliderChangeComplete(val)}
            marks={{
              [min]: min,
              [max]: max,
            }}
            {...rest}
          />
          <ErrorMessage {...meta} />
          <div className="row mt-[24px]">
            {
              (showToDestroyResponse && rangeValue !== undefined && !def) &&
                <span onClick={this.handleResetRange} className="link float-right">
                  <FormattedMessage id="candidates.delete_answer" />
                </span>
            }
          </div>
        </div>
      </div>
    );
  }
}

RenderRangeField.propTypes = propTypes;
RenderRangeField.defaultProps = defaultProps;

export default RenderRangeField;
