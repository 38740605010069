import React from 'react';
import { FormSection, Field, FieldArray, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import $ from 'jquery';

import RenderField from 'common-components/form/RenderField';
import RenderCheckboxField from 'common-components/form/RenderCheckboxField';
import { buildBody } from 'utils/api/builder';
import { required } from 'utils/form/validations';
import PersonalInfo from './PersonalInfo';
import Employments from './Employments';
import Location from './Location';
import JobPreference from './JobPreference';
import SocialMetadata from './SocialMetadata';
import ProfessionalExperiences from './ProfessionalExperiences';
import ProfessionalEducations from './ProfessionalEducations';
import ComplementaryEducations from './ComplementaryEducations';
import ProfessionalLanguages from './ProfessionalLanguages';
import CompanyQuestions from './CompanyQuestions';
import AvailabilityDates from './AvailabilityDates';
import CustomFields from '../../custom/headhunter-jobs/CustomFields';
import RenderFormErrors from '../../custom/headhunter-jobs/RenderFormErrors';

const formName = 'registration';

const propTypes = {
  candidateAttributes: PropTypes.shape({
    userRepresentationId: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.number,
    cvLibraryToken: PropTypes.string,
    desiredSalary: PropTypes.string,
    phone: PropTypes.string,
    professionalTitle: PropTypes.string,
    cvData: PropTypes.shape({
      id: PropTypes.number,
      file: PropTypes.shape({}),
      fileName: PropTypes.string,
      fileType: PropTypes.string,
      fileUrl: PropTypes.string,
    }),
    personalData: PropTypes.shape(),
    experiences: PropTypes.arrayOf(PropTypes.shape({})),
    educations: PropTypes.arrayOf(PropTypes.shape({})),
    complementaryEducations: PropTypes.arrayOf(PropTypes.shape({})),
    languages: PropTypes.arrayOf(PropTypes.shape({})),
    jobPreference: PropTypes.shape(),
    employments: PropTypes.arrayOf(PropTypes.shape({})),
    userLocation: PropTypes.shape(),
    customFieldsResponses: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  companyQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  companyQuestionTypes: PropTypes.shape({}),
  formData: PropTypes.shape({
    company: PropTypes.shape(),
    job: PropTypes.shape(),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  job: PropTypes.shape({
    company_id: PropTypes.number,
    id: PropTypes.number,
  }),
  legalMessage: PropTypes.string,
  legalInfoText: PropTypes.string,
  questionTypes: PropTypes.shape({
    checkbox: PropTypes.number,
    radio: PropTypes.number,
    select: PropTypes.number,
    text: PropTypes.number,
  }),
  submitting: PropTypes.bool.isRequired,
  languageList: PropTypes.arrayOf(PropTypes.string).isRequired,
  languageLevels: PropTypes.objectOf(PropTypes.number).isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  customFieldsForm: PropTypes.arrayOf(PropTypes.shape({})),
  idDocumentFieldsForm: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  company: PropTypes.shape({
    companyColor: PropTypes.string,
  }).isRequired,
  formType: PropTypes.string.isRequired,
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const defaultProps = {
  job: undefined,
  candidateAttributes: undefined,
  companyQuestions: [],
  legalMessage: null,
  legalInfoText: null,
  systemFieldsForm: [],
  questionTypes: {
    checkbox: 2,
    radio: 3,
    select: 1,
    text: 4,
  },
  customFieldsForm: undefined,
  idDocumentFieldsForm: undefined,
  companyQuestionTypes: undefined,
};

class RegistrationUserLoggedForm extends React.Component {
  constructor(props) {
    super(props);

    this.companyQuestionsPresenter = this.companyQuestionsPresenter.bind(this);
    this.fieldLabel = this.fieldLabel.bind(this);
    this.fieldValidation = this.fieldValidation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderField = this.renderField.bind(this);
    this.renderArrayFields = this.renderArrayFields.bind(this);

    this.state = {
      formErrors: null,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      candidateAttributes: {
        userRepresentationId,
        personalData: {
          firstName, lastName, availabilityEndDate,
          availabilityInitDate, birthDate, facebook,
          linkedin, other, phone, google,
          title: professionalTitle, twitter,
          videocurriculum, birthPlace, dni, dniType,
          userMetadataId, professionalMetadataId,
          socialMetadataId, gender,
        },
        experiences,
        educations,
        complementaryEducations,
        languages,
        jobPreference: {
          id: jobPreferenceId,
          desiredSalary,
          minSalaryAccepted,
          preferredJob,
        },
        employments,
        userLocation: {
          id: userLocationId,
          regionId,
          address,
          postalCode,
        },
        cvData: {
          id: fileId, fileUrl, fileName, file,
        },
        customFieldsResponses,
      }, initialize,
      customFieldsForm, formData: { job: { id } },
    } = this.props;

    const assetData = fileUrl === undefined ?
      { id: fileId, file }
      : { file: fileUrl, file_file_name: fileName };

    const birthDateData = birthDate === null ? '' : `${birthDate} 12:00`;

    const regioData = (regionId === undefined || regionId === null) ?
      undefined
      : { value: regionId.uid, label: regionId.route };

    const candidateResponses = {};
    for (const customField of customFieldsForm) {
      candidateResponses[customField.id] = [];
      const fieldAnswers = customField.fields.map((field) => {
        let response;
        if (field.type === 'CustomField::Fields::MultiSelectType') {
          response = { options: [] };
        } else if (field.type === 'CustomField::Fields::SelectType' ||
        field.type === 'CustomField::Fields::RadioType') {
          response = { option_id: '' };
        } else if (field.type === 'CustomField::Fields::RangeType') {
          response = { range: [field.limitMin, field.limitMax] };
        } else if (field.type === 'CustomField::Fields::FileType') {
          response = { asset: {} };
        } else {
          response = { response: '' };
        }
        return { [field.fieldCode]: response };
      });
      candidateResponses[customField.id] = fieldAnswers;
    }

    for (const response of customFieldsResponses) {
      let candidateAnswer;
      const fieldAnswers = response.candidateAnswers.map((answer) => {
        if (answer.type === 'CustomField::FieldAnswers::MultiSelectType') {
          candidateAnswer = {
            id: answer.responseId,
            options: answer.options,
          };
        } else if (answer.type === 'CustomField::FieldAnswers::SelectType' ||
                    answer.type === 'CustomField::FieldAnswers::RadioType') {
          candidateAnswer = {
            id: answer.responseId,
            option_id: answer.optionId,
          };
        } else if (answer.type === 'CustomField::FieldAnswers::RangeType') {
          candidateAnswer = {
            id: answer.responseId,
            range: [Number(answer.rangeMin), Number(answer.rangeMax)],
          };
        } else if (answer.type === 'CustomField::FieldAnswers::FileType') {
          candidateAnswer = {
            id: answer.responseId,
            assetId: answer.asset.file.id,
            asset: answer.asset.file,
          };
        } else {
          candidateAnswer = {
            id: answer.responseId,
            response: answer.response,
          };
        }
        return { [answer.fieldCode]: candidateAnswer };
      });

      candidateResponses[response.groupId] = fieldAnswers;
    }

    const initData = {
      job_id: id,
      user: {
        user_representations_attributes: [
          {
            id: userRepresentationId,
            user_metadata_attributes: {
              id: userMetadataId,
              first_name: firstName,
              last_name: lastName,
              birth_date: birthDateData,
              birth_place: birthPlace,
              dni,
              dni_type: dniType,
              gender,
            },
            social_metadata_attributes: {
              id: socialMetadataId,
              other,
              linkedin,
              twitter,
              facebook,
              google,
            },
            professional_metadata_attributes: {
              id: professionalMetadataId,
              title: professionalTitle,
              phone,
              availability_init_date: availabilityInitDate,
              availability_end_date: availabilityEndDate,
              videocurriculum,
              professional_experiences_attributes: experiences,
              professional_educations_attributes: educations,
              complementary_educations_attributes: complementaryEducations,
              professional_languages_attributes: languages,
              assets_attributes: [assetData],
            },
            user_location_attributes: {
              id: userLocationId,
              region_id: regioData,
              address,
              postal_code: postalCode,
            },
            job_preference_attributes: {
              id: jobPreferenceId,
              desired_salary: desiredSalary,
              min_salary_accepted: minSalaryAccepted,
              preferred_job: preferredJob,
            },
          },
        ],
        employments_attributes: employments,
      },
      custom_field_groups: {
        responses: candidateResponses,
      },
      responses_attributes: this.companyQuestionsPresenter(),
    };

    initialize(initData);
  }

  handleSubmit(values) {
    const url = Routes.candidates_candidacy_index_path();

    this.setState({ formErrors: null });
    $('#loader-wrapper').parent().show();

    $.ajax({
      url,
      type: 'POST',
      beforeSend: (xhr) => xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')),
      data: buildBody(values),
      contentType: false,
      processData: false,
      success: () => {
        $('#loader-wrapper').parent().hide();
      },
      error: (data) => {
        const dataProcessed = data;
        if (dataProcessed.status === 403) {
          const error = <FormattedMessage id="shared.form.errors.forbidden_firewall" />;
          dataProcessed['responseJSON'] = { errors: [error] };
        }
        this.setState({ formErrors: dataProcessed.responseJSON.errors });
        $('#loader-wrapper').parent().hide();
        document.querySelector('.send-cv-module').scrollIntoView({ behavior: 'smooth' });
      },
    });
  }

  companyQuestionsPresenter() {
    const { companyQuestions } = this.props;
    return companyQuestions.map((question) => (
      { id: question.id, question_type: question.question_type }
    ));
  }

  fieldLabel(field, label) {
    return field.required ? (
      <span>
        *
        {label}
      </span>
    ) : label;
  }

  fieldValidation(field, extraValidations = null) {
    const validations = extraValidations ? [required].concat(extraValidations) : required;

    return field.required ? validations : extraValidations;
  }

  renderField(label, name, validate, placeholder) {
    const {
      intl: { messages },
    } = this.props;

    return (
      <Field
        className="block-input"
        component={RenderField}
        label={label}
        name={name}
        type="text"
        validate={validate}
        placeholder={messages[placeholder]}
      />
    );
  }

  renderArrayFields(component, name, options = {}) {
    const { systemFieldsForm } = this.props;

    return (
      <FieldArray
        component={component}
        fieldLabel={this.fieldLabel}
        fieldValidation={this.fieldValidation}
        formName={formName}
        name={name}
        systemFieldsForm={systemFieldsForm}
        renderField={this.renderField}
        {...options}
      />
    );
  }

  render() {
    const { formErrors } = this.state;

    const {
      handleSubmit,
      submitting,
      systemFieldsForm,
      idDocumentFieldsForm,
      customFieldsForm,
      legalMessage, languageLevels, languageList,
      companyQuestions, legalInfoText,
      companyQuestionTypes,
      company: {
        companyColor,
      }, countries,
      intl: { messages },
      candidateAttributes: {
        employments,
        personalData: { dniType },
      },
      genders, formType,
      formData: { job: { company_id: companyId } },
    } = this.props;

    const rgpdLabelText = (
      <span
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: legalMessage,
        }}
      />
    );
    const rgpdLabel = (
      <div className="register-message">
        <div className="text">
          {rgpdLabelText}
        </div>
        <span className="require">*</span>
      </div>
    );

    const titleStyle = { color: companyColor };
    const style = { };

    return (
      <div>
        <RenderFormErrors
          formErrors={formErrors}
        />
        {
          formType !== 'closed-offer' && (
            <div className="title" style={titleStyle}>
              <FormattedMessage id="jobs.apply" />
            </div>
          )
        }
        <div className="form">
          <div className="form-description">
            <FormattedMessage id="jobs.jobcenter_message" />
          </div>

          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div>
              <FormSection name="user">
                <PersonalInfo
                  fieldLabel={this.fieldLabel}
                  fieldValidation={this.fieldValidation}
                  formName={formName}
                  dniType={dniType}
                  systemFieldsForm={systemFieldsForm}
                  idDocumentFieldsForm={idDocumentFieldsForm}
                  renderField={this.renderField}
                  genders={genders}
                  userLoggedIn
                />
              </FormSection>
              <Employments
                companyId={companyId}
                fieldLabel={this.fieldLabel}
                fieldValidation={this.fieldValidation}
                formName={formName}
                systemFieldsForm={systemFieldsForm}
                name="user.employments_attributes[0]"
                renderField={this.renderField}
                employments={employments}
              />
              <FormSection name="user.user_representations_attributes[0]">
                <FormSection name="user_location_attributes">
                  <Location
                    countries={countries}
                    fieldLabel={this.fieldLabel}
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    renderField={this.renderField}
                  />
                </FormSection>
                <FormSection name="job_preference_attributes">
                  <JobPreference
                    fieldLabel={this.fieldLabel}
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    renderField={this.renderField}
                  />
                </FormSection>
                <FormSection name="professional_metadata_attributes">
                  <AvailabilityDates
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    messages={messages}
                  />
                </FormSection>
                <FormSection name="social_metadata_attributes">
                  <SocialMetadata
                    fieldLabel={this.fieldLabel}
                    fieldValidation={this.fieldValidation}
                    systemFieldsForm={systemFieldsForm}
                    renderField={this.renderField}
                  />
                </FormSection>
                <FormSection name="professional_metadata_attributes">
                  {
                    this.renderArrayFields(
                      ProfessionalExperiences,
                      'professional_experiences_attributes',
                    )
                  }
                  {
                    this.renderArrayFields(
                      ProfessionalEducations,
                      'professional_educations_attributes',
                    )
                  }
                  {
                    this.renderArrayFields(
                      ComplementaryEducations,
                      'complementary_educations_attributes',
                    )
                  }
                  {
                    this.renderArrayFields(
                      ProfessionalLanguages,
                      'professional_languages_attributes',
                      {
                        languageLevels,
                        languageList,
                      },
                    )
                  }
                </FormSection>
              </FormSection>
              { customFieldsForm.length > 0 && (
                <FormSection name="custom_field_groups">
                  <CustomFields
                    customFieldsForm={customFieldsForm}
                    style={style}
                  />
                </FormSection>
              )}
            </div>

            <CompanyQuestions
              questionTypes={companyQuestionTypes}
              companyQuestions={companyQuestions}
              formName={formName}
            />

            <div className="conditions-job-center">
              <div className="conditions-wrapper">
                <Field
                  className="block-terms-and-conditions block-input form-check"
                  component={RenderCheckboxField}
                  label={rgpdLabel}
                  name="terms_and_conditions"
                  validate={required}
                />
              </div>
              <div className="register-btn">
                <button
                  className="employment-center-register-btn"
                  type="submit"
                  disabled={submitting}
                >
                  <FormattedMessage id="jobs.send_cv" />
                </button>
              </div>
              <div
                dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                  __html: legalInfoText,
                }}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

RegistrationUserLoggedForm.propTypes = propTypes;
RegistrationUserLoggedForm.defaultProps = defaultProps;

export default reduxForm({ form: formName })(injectIntl(RegistrationUserLoggedForm));
