import React from 'react';
import PropTypes from 'prop-types';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import { injectIntl } from 'react-intl';
import Icon from 'common-components/Icon';
import markToDestroy from 'utils/form/mark-to-destroy';
import RenderDateField from 'common-components/form/RenderDateField';

const propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  renderField: PropTypes.func.isRequired,
  companyColor: PropTypes.string,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
    formatMessage: PropTypes.func,
  }).isRequired,
};

const defaultProps = {
  companyColor: null,
  systemFieldsForm: [],
};

function ComplementaryEducations(props) {
  const {
    fields, fieldLabel, fieldValidation, formName, renderField, companyColor,
    intl: { formatMessage },
    systemFieldsForm: {
      complementary_education: complementaryEducationField,
    },
    intl: { messages },
  } = props;

  if (complementaryEducationField === undefined) return null;

  const placeholders = {
    month: formatMessage({ id: 'jobs.time.month' }),
    year: formatMessage({ id: 'jobs.time.year' }),
  };

  return (
    <div>
      <div className="title" style={{ color: companyColor }}>{messages['jobs.complementary_training']}</div>
      {fields.map((field, index) => {
        if (fields.get(index)._destroy) return null;
        const key = field + index;

        return (
          <div key={key} className="block">
            {
              renderField(
                fieldLabel(complementaryEducationField, messages['jobs.training']),
                `${field}.degree`,
                fieldValidation(complementaryEducationField),
                'jobs.training',
              )
            }
            {
              renderField(
                fieldLabel(complementaryEducationField, messages['jobs.center']),
                `${field}.educationCenter`,
                fieldValidation(complementaryEducationField),
                'jobs.center',
              )
            }
            <Field
              className="block-input"
              component={RenderDateField}
              formName={formName}
              label={messages['jobs.since']}
              name={`${field}.initDate`}
              withoutDay
              placeholders={placeholders}
            />
            <Field
              className="block-input"
              component={RenderDateField}
              formName={formName}
              label={messages['jobs.until']}
              name={`${field}.endDate`}
              withoutDay
              placeholders={placeholders}
            />
            <button type="button" onClick={() => markToDestroy(index, fields)}>
              <Icon name="huge-cancel-circle" />
            </button>
          </div>
        );
      })}
      <div className="add" onClick={() => fields.push({})}>
        {messages['jobs.add_complementary_training']}
      </div>
    </div>
  );
}

ComplementaryEducations.propTypes = propTypes;
ComplementaryEducations.defaultProps = defaultProps;

export default injectIntl(ComplementaryEducations);
