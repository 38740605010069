import React from 'react';
import { Field } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { loadSuggestedLocationOptions } from 'utils/helpers';
import RenderSelectField from 'common-components/form/RenderSelectField';
import RenderAsyncSelectField from 'common-components/form/RenderAsyncSelectField';
import Label from 'common-components/form/Label';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  renderField: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  countries: [],
  systemFieldsForm: [],
};

class Location extends React.Component {
  constructor(props) {
    super(props);

    this.countriesList = this.countriesList.bind(this);
    this.formatData = this.formatData.bind(this);
  }

  countriesList() {
    const { countries, intl: { formatMessage } } = this.props;

    return countries.map((country) => (
      { value: country, label: formatMessage({ id: `shared.countries.${country}` }) }
    ));
  }

  formatData(data) {
    return data.suggests.map((suggest) => (
      { value: suggest.payload.city_id, label: suggest.text }
    ));
  }

  render() {
    const {
      fieldLabel, fieldValidation, renderField,
      systemFieldsForm: {
        user_location_address: addressField,
        user_location_postal_code: postalCodeField,
        user_location_city: cityField,
      },
    } = this.props;

    return (
      <div>
        {
          addressField &&
            renderField(
              fieldLabel(addressField, <FormattedMessage id="jobs.address" />),
              'address',
              fieldValidation(addressField),
              'jobs.address',
            )
        }
        {
          postalCodeField &&
            renderField(
              fieldLabel(postalCodeField, <FormattedMessage id="jobs.postal_code" />),
              'postal_code',
              fieldValidation(postalCodeField),
              'jobs.postal_code',
            )
        }
        {
          cityField && (
            <div className="block-input">
              <Label label={fieldLabel(cityField, <FormattedMessage id="jobs.city" />)} />
              <div className="field-container">
                <Field
                  className="block-input"
                  component={RenderSelectField}
                  label={false}
                  name="country_code"
                  options={this.countriesList()}
                  placeholder={<FormattedMessage id="jobs.country" />}
                />
                <Field
                  className="block-input"
                  component={RenderAsyncSelectField}
                  label={false}
                  name="region_id"
                  loadOptions={(input) => loadSuggestedLocationOptions(input)}
                  validate={fieldValidation(cityField)}
                  placeholder={<FormattedMessage id="jobs.city_select" />}
                />
              </div>
            </div>
          )
}
      </div>
    );
  }
}

Location.propTypes = propTypes;
Location.defaultProps = defaultProps;

export default injectIntl(Location);
