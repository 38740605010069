import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findAll } from 'highlight-words-core';
import parser from 'html-react-parser';

import { splitParams } from 'utils/helpers';

const propTypes = {
  filterParams: PropTypes.string,
  headhunterJobsFilters: PropTypes.shape(),
  text: PropTypes.string,
  headhunterJobs: PropTypes.bool,
};

const defaultProps = {
  text: null,
  filterParams: null,
  headhunterJobsFilters: null,
  headhunterJobs: false,
};

function HighlighterKeywords(props) {
  const {
    text, filterParams,
    headhunterJobs, headhunterJobsFilters,
  } = props;

  let words = '';

  if (filterParams && !headhunterJobs) {
    const params = splitParams(filterParams);
    words = params.keywords !== undefined ? decodeURIComponent(params.keywords) : '';
    if (words === '' && params.main_keywords !== undefined) words = decodeURIComponent(params.main_keywords);
  }

  if (headhunterJobsFilters && Object.keys(headhunterJobsFilters).length) {
    words = headhunterJobsFilters.title !== undefined ? decodeURIComponent(headhunterJobsFilters.title) : '';
  }

  const regex = /(<<|>>)/g;
  const quotesRegexp = /["]/g;
  const wordsRegexp = /\b[^\s]+\b/g;
  const searchWords = words.replace(quotesRegexp, '').match(wordsRegexp) || [''];
  const textToHighlight = (text !== undefined && text !== null) ? text.replace(regex, '') : text;

  function sanitize(rawText) {
    if (!rawText) return rawText;

    const diacriticRegexp = /\p{Diacritic}/gu;
    // Normalize and unaccent text to compare
    return rawText.normalize('NFD').replace(diacriticRegexp, '');
  }

  const chunks = findAll({
    caseSensitive: false,
    autoEscape: true,
    sanitize,
    searchWords,
    textToHighlight,
  });

  const formattedHTML = chunks.map((chunk) => {
    const { end, highlight, start } = chunk;
    const t = textToHighlight.substr(start, end - start);
    if (highlight) {
      return `<mark class="textHighlight">${t}</mark>`;
    }
    return t;
  }).join('');

  return (
    parser(formattedHTML)
  );
}

const mapStateToProps = (store) => ({
  filterParams: store.candidates.candidatesFilterParams,
  headhunterJobsFilters: store.headhunterJobs.headhunterJobsFilters,
});

HighlighterKeywords.propTypes = propTypes;
HighlighterKeywords.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(HighlighterKeywords);
