import React from 'react';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import Icon from 'common-components/Icon';
import PropTypes from 'prop-types';
import RenderTextareaField from 'common-components/form/RenderTextareaField';
import markToDestroy from 'utils/form/mark-to-destroy';
import RenderDateField from 'common-components/form/RenderDateField';

const propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  renderField: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
    formatMessage: PropTypes.func,
  }).isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
};

function ProfessionalExperiences(props) {
  const {
    fields, fieldLabel, fieldValidation, formName, renderField,
    intl: { formatMessage },
    systemFieldsForm: {
      professional_experience: professionalExperience,
    },
  } = props;

  if (professionalExperience === undefined) return null;

  const placeholders = {
    month: formatMessage({ id: 'jobs.time.month' }),
    year: formatMessage({ id: 'jobs.time.year' }),
  };

  return (
    <div>
      <div className="title">
        <FormattedMessage id="jobs.experiences" />
      </div>
      {fields.map((field, index) => {
        if (fields.get(index)._destroy) return null;
        const key = field + index;

        return (
          <div key={key} className="block">
            {
              renderField(
                fieldLabel(professionalExperience, <FormattedMessage id="jobs.position" />),
                `${field}.position`,
                fieldValidation(professionalExperience),
                'jobs.position',
              )
            }
            {
              renderField(
                fieldLabel(professionalExperience, <FormattedMessage id="jobs.company" />),
                `${field}.companyName`,
                fieldValidation(professionalExperience),
                'jobs.company',
              )
            }
            <Field
              className="block-input"
              component={RenderDateField}
              formName={formName}
              label={<FormattedMessage id="jobs.since" />}
              name={`${field}.initDate`}
              type="text"
              withoutDay
              placeholders={placeholders}
            />
            <Field
              className="block-input"
              component={RenderDateField}
              formName={formName}
              label={<FormattedMessage id="jobs.until" />}
              name={`${field}.endDate`}
              type="text"
              withoutDay
              placeholders={placeholders}
            />
            <Field
              className="block-input"
              component={RenderTextareaField}
              label={formatMessage({ id: 'jobs.description' })}
              name={`${field}.description`}
              placeholder={formatMessage({ id: 'jobs.description' })}
            />
            <button type="button" onClick={() => markToDestroy(index, fields)}>
              <Icon name="huge-cancel-circle" />
            </button>
          </div>
        );
      })}
      <div className="add" onClick={() => fields.push({})}>
        <FormattedMessage id="jobs.add_experience" />
      </div>
    </div>
  );
}

ProfessionalExperiences.propTypes = propTypes;
ProfessionalExperiences.defaultProps = defaultProps;

export default injectIntl(ProfessionalExperiences);
