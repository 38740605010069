import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { required, email } from 'utils/form/validations';
import PropTypes from 'prop-types';

import RenderField from 'common-components/form/RenderField';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  styleButtons: PropTypes.objectOf(PropTypes.string).isRequired,
  loginError: PropTypes.bool,
  forgotPasswordUrl: PropTypes.string.isRequired,
  canBeLoginSubmitted: PropTypes.bool.isRequired,
};

const defaultProps = {
  loginError: false,
};

function ModalLogin(props) {
  const {
    handleSubmit, styleButtons, loginError,
    forgotPasswordUrl, canBeLoginSubmitted,
  } = props;

  return (
    <div>
      <h3>
        <FormattedMessage id="jobs.job_centers.login_modal.title" />
      </h3>
      <p>
        <FormattedMessage id="jobs.job_centers.login_modal.description" />
      </p>
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <Field
            className="block-input"
            component={RenderField}
            name="user.email"
            type="text"
            placeholder="E-mail"
            validate={[required, email]}
          />
          {
            loginError && (
              <span className="error">
                <span><FormattedMessage id="jobs.job_centers.login_modal.error" /></span>
              </span>
            )
          }

          <Field
            className="block-input"
            component={RenderField}
            name="user.password"
            type="password"
            placeholder="Contraseña"
            validate={required}
          />

          <p>
            <a href={forgotPasswordUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="jobs.job_centers.login_modal.forgot_password" />
            </a>
          </p>

          <div className="button-content">
            <button
              disabled={!canBeLoginSubmitted}
              style={styleButtons}
              className="button"
              type="submit"
            >
              <FormattedMessage id="jobs.job_centers.login_modal.send" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const formOptions = {
  form: 'user',
  returnRejectedSubmitPromise: true,
};

ModalLogin.propTypes = propTypes;
ModalLogin.defaultProps = defaultProps;

const wrappedComponent = injectIntl(ModalLogin);
export default reduxForm(formOptions)(wrappedComponent);
