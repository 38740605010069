import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { url, skypeUsername } from 'utils/form/validations';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  renderField: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
};

export default function SocialMetadata(props) {
  const {
    fieldLabel, fieldValidation, renderField,
    systemFieldsForm: {
      linkedin: linkedinField,
      twitter: twitterField,
      facebook: facebookField,
      google: googleField,
      skype: skypeField,
      other: otherField,
    },
  } = props;

  return (
    <div>
      {
        linkedinField &&
          renderField(
            fieldLabel(
              linkedinField,
              <FormattedMessage id="jobs.social_metadata.linkedin" />,
            ),
            'linkedin',
            fieldValidation(linkedinField, url),
            'jobs.social_metadata.linkedin',
          )
      }
      {
        twitterField &&
          renderField(
            fieldLabel(
              twitterField,
              <FormattedMessage id="jobs.social_metadata.twitter" />,
            ),
            'twitter',
            fieldValidation(twitterField, url),
            'jobs.social_metadata.twitter',
          )
      }
      {
        facebookField &&
          renderField(
            fieldLabel(
              facebookField,
              <FormattedMessage id="jobs.social_metadata.facebook" />,
            ),
            'facebook',
            fieldValidation(facebookField, url),
            'jobs.social_metadata.facebook',
          )
      }
      {
        googleField &&
          renderField(
            fieldLabel(
              googleField,
              <FormattedMessage id="jobs.social_metadata.google" />,
            ),
            'google',
            fieldValidation(googleField, url),
            'jobs.social_metadata.google',
          )
      }
      {
        skypeField &&
          renderField(
            fieldLabel(
              skypeField,
              <FormattedMessage id="jobs.social_metadata.skype" />,
            ),
            'skype',
            fieldValidation(skypeField, skypeUsername),
            'jobs.social_metadata.skype',
          )
      }
      {
        otherField &&
          renderField(
            fieldLabel(
              otherField,
              <FormattedMessage id="jobs.social_metadata.other" />,
            ),
            'other',
            fieldValidation(otherField, url),
            'jobs.social_metadata.other',
          )
      }
    </div>
  );
}

SocialMetadata.propTypes = propTypes;
SocialMetadata.defaultProps = defaultProps;
