import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import RenderField from 'common-components/form/RenderField';
import RenderDateField from 'common-components/form/RenderDateField';
import RenderSelectField from 'common-components/form/RenderSelectField';
import {
  required, email, date, phone, url, age,
} from 'utils/form/validations';
import RenderAttachFileRegistrationForm from './RenderAttachFileRegistrationForm';
import IdDocumentField from './IdDocumentField';

const propTypes = {
  dniType: PropTypes.string,
  userLoggedIn: PropTypes.bool,
  validExistingEmail: PropTypes.func,
  showExistingEmail: PropTypes.bool,
  showExistingCandidacy: PropTypes.bool,
  fieldLabel: PropTypes.func,
  fieldValidation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  renderField: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  idDocumentFieldsForm: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const defaultProps = {
  dniType: undefined,
  userLoggedIn: undefined,
  showExistingEmail: false,
  showExistingCandidacy: false,
  validExistingEmail: undefined,
  fieldLabel: undefined,
  systemFieldsForm: [],
  idDocumentFieldsForm: {
    fields: [],
  },
};

function PersonalInfo(props) {
  const {
    fieldLabel, fieldValidation, formName, renderField,
    intl: { formatMessage },
    systemFieldsForm: {
      professional_metadata_title: titleField,
      user_birth_date: birthDateField,
      user_birth_place: birthPlaceField,
      assets: assetField,
      professional_metadata_phone: phoneField,
      professional_metadata_videocurriculum: videocurriculumField,
      gender: genderField,
    },
    idDocumentFieldsForm, userLoggedIn, validExistingEmail, showExistingEmail,
    showExistingCandidacy, genders, dniType,
  } = props;
  const userMetadata = 'user_representations_attributes[0].user_metadata_attributes.';
  const profMetadata = 'user_representations_attributes[0].professional_metadata_attributes.';

  const placeholders = {
    day: formatMessage({ id: 'jobs.time.day' }),
    month: formatMessage({ id: 'jobs.time.month' }),
    year: formatMessage({ id: 'jobs.time.year' }),
  };

  return (
    <div>
      {
        !userLoggedIn && (
          <Field
            className="block-input"
            component={RenderField}
            label={(
              <span>
                *
                <FormattedMessage id="jobs.email" />
              </span>
            )}
            name="email"
            type="text"
            validate={[required, email]}
            placeholder={formatMessage({ id: 'jobs.email' })}
            onBlur={validExistingEmail}
          />
        )
      }
      {
        showExistingCandidacy && (
          <div className="special-error">
            <span className="error">
              <span><FormattedMessage id="jobs.job_centers.existing_candidacy.message" /></span>
            </span>
          </div>
        )
      }
      {
        !showExistingCandidacy && showExistingEmail && (
          <div className="special-error">
            <span className="info">
              <span><FormattedMessage id="jobs.job_centers.existing_email.message" /></span>
            </span>
          </div>
        )
      }

      {
        renderField(
          <span>
            *
            <FormattedMessage id="jobs.name" />
          </span>,
          `${userMetadata}first_name`,
          required,
          'jobs.name',
        )
      }
      {
        renderField(
          <span>
            *
            <FormattedMessage id="jobs.surnames" />
          </span>,
          `${userMetadata}last_name`,
          required,
          'jobs.surnames',
        )
      }

      {
        assetField && (
          <FieldArray
            component={RenderAttachFileRegistrationForm}
            formName={formName}
            name={`${profMetadata}assets_attributes`}
            assetField={assetField}
          />
        )
      }
      {
        genderField && (
          <Field
            className="block-input"
            component={RenderSelectField}
            label={fieldLabel(genderField, <FormattedMessage id="jobs.gender" />)}
            name={`${userMetadata}gender`}
            options={genders}
            validate={fieldValidation(genderField)}
            placeholder={<FormattedMessage id="jobs.gender" />}
            clearable
          />
        )
      }
      { (idDocumentFieldsForm.fields !== undefined) && (idDocumentFieldsForm.fields.length > 0) && (
        <div>
          <IdDocumentField
            idDocumentFieldsForm={idDocumentFieldsForm}
            dniType={dniType}
          />
        </div>
      )}
      {
        titleField &&
          renderField(
            fieldLabel(titleField, <FormattedMessage id="jobs.profession" />),
            `${profMetadata}title`,
            fieldValidation(titleField),
            'jobs.profession',
          )
      }
      {
        birthDateField && (
          <Field
            className="block-input"
            component={RenderDateField}
            formName={formName}
            label={fieldLabel(birthDateField, <FormattedMessage id="jobs.birthdate" />)}
            name={`${userMetadata}birth_date`}
            type="text"
            validate={fieldValidation(birthDateField, [age, date])}
            placeholders={placeholders}
          />
        )
      }
      {
        birthPlaceField &&
          renderField(
            fieldLabel(birthPlaceField, <FormattedMessage id="jobs.birth_place" />),
            `${userMetadata}birth_place`,
            fieldValidation(birthPlaceField),
            'jobs.birth_place',
          )
      }
      {
        phoneField &&
          renderField(
            fieldLabel(phoneField, <FormattedMessage id="jobs.phone" />),
            `${profMetadata}phone`,
            fieldValidation(phoneField, phone),
            'jobs.phone',
            'tel',
          )
      }
      {
        videocurriculumField &&
          renderField(
            fieldLabel(videocurriculumField, <FormattedMessage id="jobs.videocurriculum" />),
            `${profMetadata}videocurriculum`,
            fieldValidation(videocurriculumField, url),
            'jobs.videocurriculum',
          )
      }
    </div>
  );
}

PersonalInfo.propTypes = propTypes;
PersonalInfo.defaultProps = defaultProps;
export default injectIntl(PersonalInfo);
