// Function to add the "_destroy" parameter that ActiveRecord uses to eliminate a relationship
// when we use FieldArray

const markToDestroy = (index, fields) => {
  const { get, insert, remove } = fields;
  const value = { ...get(index), _destroy: true };
  remove(index);

  if (value.id) setTimeout(() => insert(index, value), 1);
};

export default markToDestroy;
