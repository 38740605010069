import React from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  hint: PropTypes.node,
  label: PropTypes.node,
};

const defaultProps = {
  className: null,
  hint: null,
  label: null,
};

function RenderInputNumberField(props) {
  const {
    input, meta, hint,
    className, label, ...rest
  } = props;

  return (
    <div className={className}>
      <Label label={label} />
      <div className="field-container">
        <input className="form-control" type="number" {...input} {...rest} />
        <ErrorMessage {...meta} />
      </div>
      {hint && <span className="hint">{hint}</span>}
    </div>
  );
}

RenderInputNumberField.propTypes = propTypes;
RenderInputNumberField.defaultProps = defaultProps;

export default RenderInputNumberField;
