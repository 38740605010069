import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common-components/Icon';
import { FormattedMessage } from 'react-intl';
import { change } from 'redux-form';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  deleteIcon: PropTypes.string,
  hint: PropTypes.node,
  label: PropTypes.node,
  labelTip: PropTypes.string,
  withPreview: PropTypes.bool,
};

const defaultProps = {
  className: null,
  deleteIcon: null,
  hint: null,
  label: null,
  labelTip: null,
  withPreview: false,
};

class RenderFileField extends React.Component {
  constructor(props) {
    super(props);

    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    if (this.props.withPreview) this.handlePreview(this.props.input.value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.withPreview) this.handlePreview(nextProps.input.value);
  }

  handleDestroy(value) {
    const { input: { name }, meta: { dispatch, form } } = this.props;

    const field = name.lastIndexOf('.') === -1
      ? name
      : name.substring(0, name.lastIndexOf('.'));

    dispatch(change(form, `${field}._destroy`, value));
  }

  handleChange(e) {
    // Fix IE attach error
    if (!e.target.files[0]) {
      e.preventDefault();
      return;
    }

    const { deleteIcon, input: { onChange } } = this.props;
    const file = e.target.files[0];

    onChange(file);
    if (deleteIcon) this.handleDestroy(false);

    const event = e;
    event.target.value = null;
  }

  handleRemove() {
    const { deleteIcon, input: { onChange } } = this.props;

    onChange(null);
    if (deleteIcon) this.handleDestroy(true);
    this.setState({ preview: null });
  }

  handlePreview(value) {
    if (value instanceof File) {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ preview: reader.result });
      };

      reader.readAsDataURL(value);
    } else if (value instanceof Object) {
      this.setState({ preview: value.url });
    } else {
      this.setState({ preview: null });
    }
  }

  render() {
    const {
      input: { name, value }, meta,
      className, deleteIcon, hint, disabled,
      label, labelTip, withPreview, ...rest
    } = this.props;
    const { preview } = this.state;
    const classWrap = className ? `form-attachment ${className}` : 'form-attachment';

    return (
      <div className={`${classWrap} ${disabled ? 'disabled' : ''} ${meta.touched && meta.error ? 'error' : ''}`}>
        <Label label={label} tip={labelTip} />
        <div className="field-container">
          <div className="row">
            <label htmlFor={name} className="attach">
              <input
                id={name}
                name={name}
                onChange={this.handleChange}
                style={{ display: 'none' }}
                type="file"
                {...rest}
              />
              <div className="file-name" title={value.name}>{value.name}</div>
              <div className="upload">
                <FormattedMessage id="shared.common_components.attach" />
              </div>
            </label>
            {
              deleteIcon && value &&
                <div className="action-icons">
                  <button type="button" onClick={this.handleRemove}>
                    <Icon name={deleteIcon} />
                  </button>
                </div>
            }
          </div>
          <ErrorMessage {...meta} />

          {
            withPreview && preview &&
              <img src={preview} alt="imagePreview" />
          }
        </div>
        {hint && <span className="hint">{hint}</span>}
      </div>
    );
  }
}

RenderFileField.propTypes = propTypes;
RenderFileField.defaultProps = defaultProps;

export default RenderFileField;
