// Remove this component after mixing https://github.com/bizneo/bizneo/pull/5118
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { injectIntl } from 'react-intl';
import { customStyles } from 'utils/helpers';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.node,
  className: PropTypes.string,
  selectorClass: PropTypes.string,
  withoutDay: PropTypes.bool,
  endDate: PropTypes.bool,
  placeholders:PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }),
};

const defaultProps = {
  className: null,
  selectorClass: 'col-6',
  label: null,
  withoutDay: false,
  endDate: false,
  placeholders: {
    day: null,
    month: null,
    year: null,
  },
};

class RenderDateField extends React.Component {
  constructor(props) {
    super(props);

    this.checkUpdate = this.checkUpdate.bind(this);
    this.handleChangeDay = this.handleChangeDay.bind(this);
    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.optionsSelect = this.optionsSelect.bind(this);
    this.optionsSelectYear = this.optionsSelectYear.bind(this);
    this.state = {
      day: null,
      month: null,
      year: null,
    };
  }

  componentDidMount() {
    let date = this.props.input.value;
    if (date !== undefined && date !== "") {
      date = new Date(date);
      this.setState({
        day: this.props.withoutDay ? null : { value: date.getDate(), label: date.getDate() },
        month: { value: date.getMonth() + 1, label: date.getMonth() + 1 },
        year: { value: date.getFullYear(), label: date.getFullYear() },
      });
    }
  }

  checkUpdate() {
    const dayAvailable = this.props.withoutDay ? true : this.state.day;

    if (dayAvailable && this.state.month && this.state.year) {
      const day = this.props.withoutDay ? { value: 1, label: 1 } : this.state.day;

      this.props.input.onChange(`${this.state.year.value}-${this.state.month.value}-${day.value}`);
    }
  }

  handleChangeDay(newValue) {
    this.setState({ day: newValue }, () => this.checkUpdate());
  }

  handleChangeMonth(newValue) {
    this.setState({ month: newValue }, () => this.checkUpdate());
  }

  handleChangeYear(newValue) {
    this.setState({ year: newValue }, () => this.checkUpdate());
  }

  optionsSelect(init, end) {
    const range = [];
    for (let i = init; i <= end; i += 1) {
      range.push({ value: i, label: i });
    }

    return range;
  }

  optionsSelectYear(init, end) {
    const range = [];
    for (let i = end; i >= init; i -= 1) {
      range.push({ value: i, label: i });
    }

    return range;
  }

  render() {
    const { label, meta, selectorClass, endDate,
      className, placeholders, intl:{ messages }
    } = this.props;

    const endYear = endDate ? new Date().getFullYear() + 10 : new Date().getFullYear();
    const yearOptions = this.optionsSelectYear(1900, endYear);

    return (
      <div className={className}>
        <Label label={label} />
        <div className={`date-box ${meta.touched && meta.error ? 'error' : ''}`}>
          <div className="date-selects row wrap">
            {!this.props.withoutDay &&
              <div className={`day ${selectorClass}`}>
                <Select
                  simpleValue
                  value={this.state.day}
                  placeholder={placeholders.day}
                  isClearable={false}
                  onChange={this.handleChangeDay}
                  options={this.optionsSelect(1, 31)}
                  styles={customStyles}
                />
              </div>
            }
            <div className={`month ${selectorClass}`}>
              <Select
                simpleValue
                value={this.state.month}
                placeholder={placeholders.month}
                isClearable={false}
                onChange={this.handleChangeMonth}
                options={this.optionsSelect(1, 12)}
                styles={customStyles}
              />
            </div>
            <div className={`year ${selectorClass}`}>
              <Select
                simpleValue
                value={this.state.year}
                placeholder={placeholders.year}
                isClearable={false}
                onChange={this.handleChangeYear}
                options={yearOptions}
                styles={customStyles}
              />
            </div>
          </div>
          <ErrorMessage {...meta} />
        </div>
      </div>
    );
  }
}

RenderDateField.propTypes = propTypes;
RenderDateField.defaultProps = defaultProps;

export default injectIntl(RenderDateField);
