import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { date } from 'utils/form/validations';
import RenderDateTimePickerField from 'common-components/form/RenderDateTimePickerField';

const propTypes = {
  fieldValidation: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.shape({
    availability: PropTypes.shape({
      required: PropTypes.bool,
    }),
  }),
  messages: PropTypes.shape({
    'jobs.registration_settings.professional_metadata.availability': PropTypes.string,
    'jobs.since': PropTypes.string,
    'jobs.until': PropTypes.string,
  }).isRequired,
};

const defaultProps = {
  systemFieldsForm: {},
};

export default function AvailabilityDates(props) {
  const {
    fieldValidation,
    systemFieldsForm: {
      availability: availabilityField,
    },
    messages,
  } = props;

  if (!availabilityField) return null;

  const asterisk = availabilityField.required ? '<span class=\'require\'>*</span>' : '';
  const label = `${messages['jobs.registration_settings.professional_metadata.availability']} ${asterisk}${messages['jobs.since']}/${messages['jobs.until']}`;

  return (
    <div className="block-input">
      <div className="block-date">
        <div className="date">
          <span
            className="label"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: label,
            }}
            title={label.replace(/\n|<.*?>/g, '')}
          />
          <div className="availability">
            <Field
              name="availability_init_date"
              component={RenderDateTimePickerField}
              validate={fieldValidation(availabilityField, date)}
              className="block-input availability"
              placeholder={messages['jobs.since']}
            />
            <Field
              name="availability_end_date"
              component={RenderDateTimePickerField}
              validate={date}
              className="block-input availability"
              placeholder={messages['jobs.until']}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AvailabilityDates.propTypes = propTypes;
AvailabilityDates.defaultProps = defaultProps;
