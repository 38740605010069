import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { injectIntl } from 'react-intl';

import { loadSuggestedLocationOptions } from 'utils/helpers';
import RenderSelectField from 'common-components/form/RenderSelectField';
import RenderAsyncSelectField from 'common-components/form/RenderAsyncSelectField';
import Label from 'common-components/form/Label';
import { firewallUnsupportedCharacters } from 'utils/form/validations';

const propTypes = {
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  renderField: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
  countries: [],
};

class Location extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectedCountry = this.handleSelectedCountry.bind(this);
    this.state = {
      countryUid: null,
    };
  }

  handleSelectedCountry(input) {
    this.setState({ countryUid: input !== '' ? input : null });
  }

  render() {
    const {
      fieldLabel, fieldValidation, renderField, countries,
      systemFieldsForm: {
        user_location_address: addressField,
        user_location_postal_code: postalCodeField,
        user_location_city: cityField,
      },
      intl: { messages },
    } = this.props;

    const { countryUid } = this.state;

    return (
      <React.Fragment>
        { (addressField || postalCodeField) &&
          <div className="row form-group">
            {
              addressField &&
                <div className="col-9 mobile-margin-bottom">
                  {
                    renderField(
                      fieldLabel(addressField, messages['jobs.address']),
                      'address',
                      fieldValidation(addressField, firewallUnsupportedCharacters),
                      messages['jobs.address'],
                      'user.user_representations_attributes[0].user_location_attributes.address',
                    )
                  }
                </div>
            }
            {
              postalCodeField &&
                <div className="col-3">
                  {
                    renderField(
                      fieldLabel(postalCodeField, messages['jobs.postal_code']),
                      'postal_code',
                      fieldValidation(postalCodeField),
                      messages['jobs.postal_code'],
                      'user.user_representations_attributes[0].user_location_attributes.postal_code',
                    )
                  }
                </div>
            }
          </div>
        }
        {
          cityField && (
            <div className="row form-group">
              <div className="col-6">
                <Label label={fieldLabel(cityField, messages['jobs.country'])} />
                <div className="field-container">
                  <div data-input-name="user.user_representations_attributes[0].user_location_attributes.country_code" data-label-text={messages['jobs.country']}>
                    <Field
                      className="block-input"
                      component={RenderSelectField}
                      clearable
                      parentCallback={this.handleSelectedCountry}
                      label={false}
                      name="country_code"
                      options={countries}
                      placeholder={messages['jobs.country_select']}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Label label={fieldLabel(cityField, messages['jobs.city'])} />
                <div data-input-name="user.user_representations_attributes[0].user_location_attributes.region_id" data-label-text={messages['jobs.city']}>
                  <Field
                    className="block-input"
                    component={RenderAsyncSelectField}
                    label={false}
                    name="region_id"
                    validate={fieldValidation(cityField)}
                    cache={false}
                    loadOptions={(input) => loadSuggestedLocationOptions(input, countryUid)}
                    placeholder={messages['jobs.city_select']}
                    isClearable
                  />
                </div>
              </div>
            </div>
          )
        }
      </React.Fragment>
    );
  }
}

Location.propTypes = propTypes;
Location.defaultProps = defaultProps;

export default injectIntl(Location);
