import ReactOnRails from 'react-on-rails';
import { jobCentersStore } from 'job-centers/redux/store';

import CustomHeadhunterJobsIndexView from './components/custom/headhunter-jobs/IndexContent';
import CustomHeadhunterJobsShowView from './components/custom/headhunter-jobs/ShowContent';
import CompaniesShowView from './components/companies/ShowContent';
import CustomHeadhunterJobsIframeIndexView from './components/custom/headhunter-jobs/IframeIndexContent';

ReactOnRails.register({
  CustomHeadhunterJobsIndexView,
  CustomHeadhunterJobsIframeIndexView,
  CustomHeadhunterJobsShowView,
  CompaniesShowView,
});

ReactOnRails.registerStore({
  jobCentersStore,
});
