import React from 'react';
import { Field, fieldArrayFieldsPropTypes } from 'redux-form';
import Icon from 'common-components/Icon';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import markToDestroy from 'utils/form/mark-to-destroy';
import RenderSelectField from 'common-components/form/RenderSelectField';
import RenderDateField from 'common-components/form/RenderDateField';
import Label from 'common-components/form/Label';
import { firewallUnsupportedCharacters } from 'utils/form/validations';

const propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  fieldLabel: PropTypes.func.isRequired,
  fieldValidation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  renderField: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
};

const defaultProps = {
  systemFieldsForm: [],
};

class ProfessionalEducations extends React.Component {
  constructor(props) {
    super(props);

    this.educationList = this.educationList.bind(this);
  }

  educationList() {
    const { intl: { messages } } = this.props;
    const educations = messages['jobs.min_educations'];

    return Object.values(educations).map((education) => ({ value: education, label: education }));
  }

  render() {
    const {
      fields, fieldLabel, fieldValidation, formName, renderField,
      systemFieldsForm: {
        education: educationField,
      },
      intl: { messages },
    } = this.props;

    if (educationField === undefined) return null;

    const placeholders = {
      month: `${messages['jobs.time.month']}`,
      year: `${messages['jobs.time.year']}`,
    };

    return (
      <div>
        <div className="title">{messages['jobs.formation']}</div>
        {fields.map((field, index) => {
          if (fields.get(index)._destroy) return null;
          const key = field + index;
          const degree = this.educationList().filter((e) => e.value === fields.get(index).degree);
          const educationId = fields.get(index).id;

          return (
            <div key={key} className="block">
              <div data-input-name={`${fields.name}[${index}].degree`} data-label-text={messages['jobs.formation']}>
                <div className="row form-group">
                  <div className="col-12">
                    <Field
                      className="block-input"
                      component={RenderSelectField}
                      label={fieldLabel(educationField, messages['jobs.formation_input_label'])}
                      name={`${field}.degree`}
                      options={this.educationList()}
                      validate={fieldValidation(educationField)}
                      placeholder={messages['jobs.formation_select']}
                    />
                    {
                      degree.length === 0 && educationId &&
                        <div className="block-input">
                          <span className="label" />
                          <span className="error">
                            {messages['jobs.warning_message_degree']}
                          </span>
                        </div>
                    }
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  {
                    renderField(
                      fieldLabel(educationField, messages['jobs.center']),
                      `${field}.educationCenter`,
                      fieldValidation(educationField, firewallUnsupportedCharacters),
                      `${messages['jobs.center']}`,
                      `${fields.name}[${index}].educationCenter`,
                    )
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="block-date">
                  <div className="date row">
                    <div className="col-6" data-input-name={`${fields.name}[${index}].initDate`} data-label-text={messages['jobs.experience_init_date']}>
                      <Label label={messages['jobs.experience_init_date']} />
                      <Field
                        withoutDay
                        component={RenderDateField}
                        formName={formName}
                        name={`${field}.initDate`}
                        placeholders={placeholders}
                      />
                    </div>
                    <div className="col-6" data-input-name={`${fields.name}[${index}].endDate`} data-label-text={messages['jobs.experience_end_date']}>
                      <Label label={messages['jobs.experience_end_date']} />
                      <Field
                        withoutDay
                        component={RenderDateField}
                        formName={formName}
                        name={`${field}.endDate`}
                        placeholders={placeholders}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="link float-right" onClick={() => markToDestroy(index, fields)}>{messages['jobs.delete_training']}</div>
              </div>
            </div>
          );
        })}
        <div className="add link link-plus" onClick={() => fields.push({})}>
          <Icon name="huge-add-circle" />
          {messages['jobs.add_qualification']}
        </div>
      </div>
    );
  }
}

ProfessionalEducations.propTypes = propTypes;
ProfessionalEducations.defaultProps = defaultProps;

export default injectIntl(ProfessionalEducations);
