/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common-components/Icon';
import { injectIntl } from 'react-intl';
import { deviceDetect } from 'utils/helpers';

const propTypes = {
  companyColor: PropTypes.string.isRequired,
  anonymousJob: PropTypes.bool.isRequired,
  companyOriginUrl: PropTypes.string,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
  inscribedInOffer: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    category: PropTypes.string,
    job_setting: PropTypes.shape({
      hide_job_in_career_sites: PropTypes.bool,
      hide_detail_box: PropTypes.bool,
      hide_location: PropTypes.bool,
      hide_publication_date: PropTypes.bool,
    }),
    subcategory: PropTypes.string,
    department: PropTypes.string,
    period_salary: PropTypes.string,
    professional_level: PropTypes.string,
    show_salary: PropTypes.bool,
    salary_description: PropTypes.string,
    status: PropTypes.string,
    sector: PropTypes.string,
    workday: PropTypes.string,
    work_mode: PropTypes.string,
  }).isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
  jobClosed: PropTypes.bool.isRequired,
  openedForm: PropTypes.bool.isRequired,
  openForm: PropTypes.func.isRequired,
  whatsappMessageLink: PropTypes.string.isRequired,
};

const defaultProps = {
  companyOriginUrl: undefined,
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.shareByEmail = this.shareByEmail.bind(this);
    this.shareByWhatsapp = this.shareByWhatsapp.bind(this);
  }

  shareByEmail() {
    const { title } = this.props.job;

    const url = `mailto:?Subject=${title}&Body=${window.location.href}`;
    window.open(url);
  }

  shareByWhatsapp() {
    const { whatsappMessageLink } = this.props;
    const url = `${whatsappMessageLink}?text=${window.location.href}`;

    window.open(url);
  }

  render() {
    const {
      job, jobClosed, companyColor,
      companyOriginUrl, inscribedInOffer,
      userLoggedIn, anonymousJob, openForm,
      intl: { messages }, openedForm,
    } = this.props;
    const facebookLink = `https://www.facebook.com/sharer.php?u=${window.location.href}`;
    const linkedinLink = `https://www.linkedin.com/shareArticle?url=${window.location.href}`;
    const twitterLink = `https://twitter.com/share?url=${window.location.href}`;
    const isMobile = deviceDetect();
    const workModeStatusShow = ['full_remote', 'occasional_remote'];

    const showFormStyle = {
      backgroundColor: companyColor,
      border: `${companyColor} solid 1px`,
    };
    const showJobDetailStyle = {
      color: companyColor,
      border: `${companyColor} solid 1px`,
    };

    const jobInternal = job.status === 'internal';

    return (
      !openedForm &&
        <div className="Sidebar">
          <div className="row-buttons">
            {
              jobClosed && !inscribedInOffer &&
                <div className="closed-offer">
                  <div className="closed-message">
                    {messages['jobs.closed_offer']}
                  </div>
                  {!anonymousJob && companyOriginUrl &&
                    <button
                      className="button"
                      style={showFormStyle}
                      onClick={() => (window.location.href = companyOriginUrl)}
                    >
                      {messages['jobs.similar_offers']}
                    </button>
                  }
                </div>
            }
            { inscribedInOffer && messages['jobs.inscribed_in_job'] }
            {
              !jobClosed && !inscribedInOffer &&
                <button
                  className="button"
                  style={openedForm ? showJobDetailStyle : showFormStyle}
                  onClick={openForm}
                >
                  {openedForm ? messages['jobs.job_detail'] : messages['jobs.send_cv']}
                </button>
            }
            {
              !anonymousJob && !jobClosed && !userLoggedIn &&
                <div className="action-enroll-job-account">
                  {messages['jobs.enroll_job_account']}
                  <button
                    className="link"
                    data-modal-content=""
                    data-target="#login-modal"
                    onClick={event => event.preventDefault()}
                  >
                    <span className="log-in">
                      {messages['jobs.log_in']}
                    </span>
                  </button>
                </div>
            }
          </div>
          <div className="actions">
            { !job.job_setting.hide_detail_box &&
              <div className="flat-box">
                <p className="h2">{messages['jobs.details']}</p>
                { job.show_salary &&
                  <div className="elements">
                    <Icon name="huge-coins-01" />
                    <div className="text">
                      <div className="title">{messages['jobs.salary']}</div>
                      <div className="data">{job.salary_description}</div>
                      <div className="data">{messages[`shared.job_data.period_salaries.${job.period_salary}`]}</div>
                    </div>
                  </div>
                }
                <div className="elements">
                  <Icon name="huge-folder-01" />
                  <div className="text">
                    <div className="title">{messages['jobs.category']}</div>
                    <div className="data">{messages[`shared.job_data.categories.${job.category}.__name__`]}</div>
                  </div>
                </div>
                <div className="elements">
                  <Icon name="huge-structure-folder" />
                  <div className="text">
                    <div className="title">{messages['jobs.subcategory']}</div>
                    <div className="data">{messages[`shared.job_data.categories.${job.category}.${job.subcategory}`]}</div>
                  </div>
                </div>
                <div className="elements">
                  <Icon name="huge-lift-truck" />
                  <div className="text">
                    <div className="title">{messages['jobs.sector']}</div>
                    <div className="data">{messages[`shared.job_data.sectors.${job.sector}`]}</div>
                  </div>
                </div>
                <div className="elements">
                  <Icon name="huge-clock-01" />
                  <div className="text">
                    <div className="title">{messages['jobs.workday']}</div>
                    <div className="data">{messages[`shared.job_data.workday_types.${job.workday}`]}</div>
                  </div>
                </div>
                { workModeStatusShow.includes(job.work_mode) &&
                  <div className="elements">
                    <Icon name="huge-laptop" />
                    <div className="text">
                      <div className="title">{messages['jobs.work_mode']}</div>
                      <div className="data">{messages[`shared.job_data.work_mode.${job.work_mode}`]}</div>
                    </div>
                  </div>
                }
                <div className="elements">
                  <Icon name="huge-briefcase-01" />
                  <div className="text">
                    <div className="title">{messages['jobs.professional_level']}</div>
                    <div className="data">{messages[`shared.job_data.professional_levels.${job.professional_level}`]}</div>
                  </div>
                </div>
                <div className="elements">
                  <Icon name="huge-bookmark-02" />
                  <div className="text">
                    <div className="title">{messages['jobs.department']}</div>
                    <div className="data">{messages[`shared.job_data.departments.${job.department}`]}</div>
                  </div>
                </div>
              </div>
            }
            {!jobClosed && !jobInternal &&
              <div className="flat-box share">
                <p className="share-title">{messages['jobs.share']}</p>
                <ul className="icons">
                  <li>
                    <a
                      className="twitter"
                      href={twitterLink}
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                    >
                      <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNS4xIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTM4OS4yIDQ4aDcwLjZMMzA1LjYgMjI0LjIgNDg3IDQ2NEgzNDVMMjMzLjcgMzE4LjYgMTA2LjUgNDY0SDM1LjhMMjAwLjcgMjc1LjUgMjYuOCA0OEgxNzIuNEwyNzIuOSAxODAuOSAzODkuMiA0OHpNMzY0LjQgNDIxLjhoMzkuMUwxNTEuMSA4OGgtNDJMMzY0LjQgNDIxLjh6Ii8+PC9zdmc+" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="facebook"
                      href={facebookLink}
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      className="linkedin"
                      href={linkedinLink}
                      rel="noopener noreferrer nofollow"
                      target="_blank"
                    >
                      Linkedin
                    </a>
                  </li>
                  {isMobile ?
                    <li>
                      <button className="whatsapp reset" onClick={this.shareByWhatsapp} />
                    </li>
                    :
                    <li>
                      <button className="email reset" onClick={this.shareByEmail}>
                        <Icon name="huge-mail-01" size="small" />
                      </button>
                    </li>
                  }
                </ul>
              </div>
            }
          </div>
        </div>
    );
  }
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default injectIntl(Sidebar);
