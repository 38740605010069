import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import getStore from 'job-centers/redux/store';
import companiesLocales from 'tmp/job-centers.json';
import sharedLocales from 'tmp/shared.json';

function checkLocale(locale) {
  const locales = {
    mx: 'es-MX',
  };

  return locales[locale] || locale;
}

export default function view(WrappedComponent) {
  function View(props, railsContext) {
    const { i18nLocale, i18nDefaultLocale } = railsContext;

    const language = checkLocale(i18nLocale);
    const languageWithoutRegionCode = language.split('-')[0];

    const translations = companiesLocales[language] ||
      companiesLocales[languageWithoutRegionCode] ||
      companiesLocales[i18nDefaultLocale];
    const sharedTranslations = sharedLocales[language] ||
      sharedLocales[languageWithoutRegionCode] ||
      sharedLocales[i18nDefaultLocale];
    const messages = { ...translations, ...sharedTranslations };

    return () => (
      <Provider store={getStore()}>
        <IntlProvider locale={language} messages={messages}>
          <WrappedComponent {...props} />
        </IntlProvider>
      </Provider>
    );
  }

  return View;
}
