import React from 'react';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { FormattedMessage } from 'react-intl';
import { customStyles } from 'utils/helpers';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  creatable: PropTypes.bool,
  label: PropTypes.node,
  labelTip: PropTypes.string,
  parentCallback: PropTypes.func,
  searchPromptText: PropTypes.string,
  hint: PropTypes.node,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: null,
  creatable: false,
  label: null,
  labelTip: null,
  parentCallback: null,
  searchPromptText: null,
  hint: null,
  disabled: false,
};

function RenderAsyncSelectField(props) {
  const {
    input, meta, disabled,
    className, creatable, label, labelTip, parentCallback, hint, searchPromptText, ...rest
  } = props;
  const AsyncComponent = creatable ? AsyncCreatableSelect : AsyncSelect;
  const defaultSearchPromptText = <FormattedMessage id="shared.common_components.type_to_search" />;

  const handleChange = (optionSelected) => {
    input.onChange(optionSelected);
    if (parentCallback) parentCallback(optionSelected);
  };

  return (
    <div className={`${className} ${meta.touched && meta.error ? 'error' : ''}`}>
      { label &&
        <Label label={label} tip={labelTip} />
      }
      <div className="normal-select">
        <AsyncComponent
          onChange={handleChange}
          isDisabled={disabled}
          noOptionsMessage={() => searchPromptText || defaultSearchPromptText}
          styles={customStyles}
          touchUi={false}
          value={input.value}
          {...rest}
        />
        <ErrorMessage {...meta} />
      </div>
      {hint && <span className="hint">{hint}</span>}
    </div>
  );
}

RenderAsyncSelectField.propTypes = propTypes;
RenderAsyncSelectField.defaultProps = defaultProps;

export default RenderAsyncSelectField;
