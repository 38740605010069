import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Icon from 'common-components/Icon';
import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.node,
  input: PropTypes.shape().isRequired,
  label: PropTypes.node,
  options: PropTypes.shape({}),
  meta: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  parentCallback: PropTypes.func,
  clearable: PropTypes.bool,
  showCalendarIcon: PropTypes.bool,
  defaultValue: PropTypes.string,
};

const defaultProps = {
  className: null,
  disabled: false,
  hint: null,
  label: null,
  options: {},
  placeholder: null,
  parentCallback: null,
  clearable: false,
  showCalendarIcon: false,
  defaultValue: '',
};

const localeConversion = {
  'es-CL': 'es',
  'sr-RS': 'sr',
  'el': 'gr',
  'pt-BR': 'pt',
  'ca': 'cat',
};

// The 'multiple' and 'range' modes have not been implemented because they are not needed
// at this moment
class RenderDateTimePickerField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.flatpickr = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.recruiterLocale = this.recruiterLocale.bind(this);
  }

  componentDidMount() {
    const {
      input: { value, onChange }, intl: { locale }, options, defaultValue,
    } = this.props;

    const val = value || defaultValue;

    const flatpickr = import(/* webpackChunkName: "flatpickr" */ 'flatpickr').then(f => f.default);
    const flatpickrLocale = (locale !== 'en')
      ? import(/* webpackChunkName: "flatpickr-locale" */ `flatpickr/dist/l10n/${this.recruiterLocale(locale)}.js`).then(l => l.default)
      : Promise.resolve();
    const defaultOptions = {
      defaultDate: value || defaultValue,
      onChange: this.handleChange,
    };

    Promise.all([flatpickr, flatpickrLocale]).then(([Flatpickr, flatLocale]) => {
      if (this.input.current === null) return;
      this.flatpickr = new Flatpickr(this.input.current, {
        ...(flatLocale && { locale: flatLocale[this.recruiterLocale(locale)] }),
        ...defaultOptions,
        ...options,
      });
    });
    if (val) onChange(val);
  }

  componentDidUpdate(prevProps) {
    const { input: { value } } = this.props;
    if (prevProps.input.value !== value && this.flatpickr !== null) {
      this.flatpickr.setDate(value, false);
    }
  }

  componentWillUnmount() {
    if (this.flatpickr) this.flatpickr.destroy();
  }

  handleChange(_selectedDates, dateStr, instance) {
    const { parentCallback, input: { onChange } } = this.props;

    if (instance.config.mode === 'single') {
      onChange(dateStr);
    }

    // Use only if you have to change the parent component
    if (parentCallback) {
      parentCallback(dateStr);
    }
  }

  handleClear() {
    const { input: { onChange } } = this.props;
    onChange('');
  }

  recruiterLocale(locale) {
    return localeConversion[locale] || locale;
  }

  render() {
    const {
      input: { name, value }, meta, clearable, showCalendarIcon,
      className, disabled, hint, label, placeholder,
    } = this.props;

    return (
      <div className={className}>
        <Label label={label} />
        <div className="field-container">
          <input
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            className="form-control"
            ref={this.input}
          />
          { showCalendarIcon &&
            <Icon name="huge-calendar-04" klass="calendar mt-[5px]" />
          }
          { clearable && value &&
            <span className="clear" onClick={this.handleClear}>×</span>
          }
          <ErrorMessage {...meta} />
        </div>
        {hint && <span className="hint">{hint}</span>}
      </div>
    );
  }
}

RenderDateTimePickerField.propTypes = propTypes;
RenderDateTimePickerField.defaultProps = defaultProps;

export default injectIntl(RenderDateTimePickerField);
