import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Icon from 'common-components/Icon';
import PropTypes from 'prop-types';

const propTypes = {
  formErrors: PropTypes.arrayOf(String),
};

const defaultProps = {
  formErrors: null,
};

class RenderFormErrors extends React.Component {
  constructor(props) {
    super(props);

    this.renderFormErrors = this.renderFormErrors.bind(this);
  }

  renderFormErrors() {
    const { formErrors } = this.props;

    return (
      <div className="alert danger no-fading full">
        <Icon name="huge-alert-circle" klass="danger msg-icon" />
        <button type="button" onClick={this.hideAlert} className="close">
          <Icon name="huge-cancel-01" />
        </button>
        <div className="message">
          <h3 className="mb-small not-base">
            { formErrors.length === 1 ?
              <FormattedMessage id="jobs.job_centers.errors_alert_title_single" values={{ errors: formErrors.length }} />
              : <FormattedMessage id="jobs.job_centers.errors_alert_title_multiple" values={{ errors: formErrors.length }} />}
          </h3>
          <ul>
            { formErrors.map((error) => (
              <li key={error}>
                { error }
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { formErrors } = this.props;

    return (
      formErrors && this.renderFormErrors()
    );
  }
}

RenderFormErrors.propTypes = propTypes;
RenderFormErrors.defaultProps = defaultProps;

export default injectIntl(RenderFormErrors);
