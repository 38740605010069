import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';

import * as jobCentersReducers from './reducers';

export function jobCentersStore(initialProps) {
  const initialState = {};
  const commonReducers = {
    form: formReducer,
  };

  Object.keys(initialProps).forEach((key) => { initialState[key] = () => initialProps[key]; });

  const reducers = combineReducers({
    ...initialState,
    ...commonReducers,
    ...jobCentersReducers,
  });

  return createStore(
    reducers,
    applyMiddleware(thunkMiddleware),
  );
}

export default function getStore() {
  return ReactOnRails.getStore('jobCentersStore');
}
