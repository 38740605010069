import React from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
};

const defaultProps = {
  className: null,
  label: null,
};

function RenderCheckboxFields(props) {
  const {
    input: { name, onChange, value }, meta,
    options, label, className,
  } = props;
  const selectedValues = value || [];

  const handleChange = (newValue) => {
    if (selectedValues.includes(newValue)) {
      onChange(selectedValues.filter(v => v !== newValue));
    } else {
      onChange(selectedValues.concat(newValue));
    }
  };

  return (
    <div className={`${className} form-group`}>
      <Label label={label} />
      {
        options.map((option) => {
          const id = option.value + name;

          return (
            <div key={id} className="inner-row form-check">
              <input
                checked={selectedValues.includes(option.value)}
                id={id}
                onChange={() => handleChange(option.value)}
                type="checkbox"
              />
              <label htmlFor={id} title={option.label} className="input_checkbox_label">
                {option.label}
              </label>
            </div>
          );
        })
      }
      <ErrorMessage {...meta} />
    </div>
  );
}

RenderCheckboxFields.propTypes = propTypes;
RenderCheckboxFields.defaultProps = defaultProps;

export default RenderCheckboxFields;
