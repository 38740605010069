import React from 'react';
import { fieldArrayFieldsPropTypes } from 'redux-form';
import Icon from 'common-components/Icon';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import markToDestroyCustomField from 'utils/form/mark-to-destroy-custom-field';
import CustomField from './CustomField';

const propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  label: PropTypes.string.isRequired,
  repeteable: PropTypes.bool.isRequired,
  customFields: PropTypes.arrayOf(PropTypes.shape({
    fieldCode: PropTypes.string,
  })).isRequired,
};

const defaultProps = {
};

const CustomFieldsArrayFields = function customFieldsArrayFields(props) {
  const {
    fields,
    label,
    customFields,
    repeteable,
  } = props;

  return (
    <div>
      {fields.map((arrayField, index) => {
        const answer = fields.get(index);
        const customField = customFields[0];
        if (customField !== null && customField !== undefined &&
          answer !== undefined && answer[customField.fieldCode] &&
          answer[customField.fieldCode]._destroy) {
          return null;
        }

        return (customFields.map((field) => {
          const extraClass = field.type === 'CustomField::Fields::FileType' ? 'cv-wrapper' : '';
          const fileField = field.type === 'CustomField::Fields::FileType';
          return (
            <div
              key={`${arrayField}_${field.fieldCode}`}
              className={`custom_field block ${extraClass}`}
            >
              <CustomField
                key={field.fieldId}
                field={field}
                name={`${arrayField}.${field.fieldCode}`}
              />
              { ((!!index && repeteable) || fileField) && (
                <div className="row form-group">
                  <div className="link float-right" onClick={() => markToDestroyCustomField(index, fields, field, fileField)}>
                    <FormattedMessage id="jobs.delete_answer" />
                  </div>
                </div>
              )}
            </div>
          );
        }));
      })}
      { repeteable && (
        <div className="add link link-plus" onClick={() => fields.push()}>
          <Icon name="huge-add-circle" />
          <FormattedMessage id="jobs.add_answer" values={{ fieldLabel: label }} />
        </div>
      )}
    </div>
  );
};

CustomFieldsArrayFields.propTypes = propTypes;
CustomFieldsArrayFields.defaultProps = defaultProps;

export default CustomFieldsArrayFields;
