import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import RenderField from 'common-components/form/RenderField';
import RenderSelectField from 'common-components/form/RenderSelectField';
import RenderCheckboxFields from 'common-components/form/RenderCheckboxFields';
import RenderRadioFields from 'common-components/form/RenderRadioFields';
import { required } from 'utils/form/validations';

const propTypes = {
  companyQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  questionTypes: PropTypes.shape({
    checkbox: PropTypes.number,
    radio: PropTypes.number,
    select: PropTypes.number,
  }),
};

const defaultProps = {
  companyQuestions: [],
  questionTypes: {
    checkbox: 2,
    radio: 3,
    select: 1,
  },
};

export default class CompanyQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.responses = this.responses.bind(this);
    this.renderSelectQuestion = this.renderSelectQuestion.bind(this);
    this.renderCheckboxQuestion = this.renderCheckboxQuestion.bind(this);
    this.renderRadioQuestion = this.renderRadioQuestion.bind(this);
    this.renderTextQuestion = this.renderTextQuestion.bind(this);
  }

  responses(question) {
    const answers = question.raw_answers;

    return answers.map((answer) => ({ value: answer.id, label: answer.title }));
  }

  renderSelectQuestion(index, question, className, label, validations) {
    return (
      <Field
        className={className}
        component={RenderSelectField}
        key={index}
        label={label}
        name={`responses_attributes[${index}].response`}
        options={this.responses(question)}
        placeholder=""
        validate={validations}
      />
    );
  }

  renderCheckboxQuestion(index, question, className, label, validations) {
    return (
      <Field
        className={className}
        component={RenderCheckboxFields}
        key={index}
        label={label}
        name={`responses_attributes[${index}].responses`}
        options={this.responses(question)}
        validate={validations}
      />
    );
  }

  renderRadioQuestion(index, question, className, label, validations) {
    return (
      <Field
        className={className}
        component={RenderRadioFields}
        key={index}
        label={label}
        name={`responses_attributes[${index}].response`}
        options={this.responses(question)}
        validate={validations}
      />
    );
  }

  renderTextQuestion(index, question, className, label, validations) {
    return (
      <Field
        className={className}
        component={RenderField}
        key={index}
        label={label}
        name={`responses_attributes[${index}].response`}
        validate={validations}
      />
    );
  }

  render() {
    const {
      companyQuestions,
      questionTypes,
    } = this.props;

    if (companyQuestions && companyQuestions.length < 1) return null;

    return (
      <div>
        <div className="title">
          <FormattedMessage id="jobs.questions" />
        </div>
        {
          companyQuestions.map((question, index) => {
            const className = 'block-question';
            const label = question.mandatory ? `*${question.title}` : question.title;
            const validations = question.mandatory ? required : undefined;

            if (question.question_type === questionTypes.select) {
              return this.renderSelectQuestion(
                index,
                question,
                className,
                label,
                validations,
              );
            } if (question.question_type === questionTypes.checkbox) {
              return this.renderCheckboxQuestion(
                index,
                question,
                className,
                label,
                validations,
              );
            } if (question.question_type === questionTypes.radio) {
              return this.renderRadioQuestion(
                index,
                question,
                className,
                label,
                validations,
              );
            }
            return this.renderTextQuestion(
              index,
              question,
              className,
              label,
              validations,
            );
          })
        }
      </div>
    );
  }
}

CompanyQuestions.propTypes = propTypes;
CompanyQuestions.defaultProps = defaultProps;
