import React from 'react';
import { connect } from 'react-redux';
import Icon from 'common-components/Icon';
import {
  FormSection, Field, FieldArray, reduxForm, getFormSyncErrors,
} from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { buildBody } from 'utils/api/builder';
import { get } from 'utils/api/fetch-client';

import RenderField from 'common-components/form/RenderField';
import RenderCheckboxField from 'common-components/form/RenderCheckboxField';
import { required } from 'utils/form/validations';
import PersonalInfo from './PersonalInfo';
import Employments from './Employments';
import Location from './Location';
import JobPreference from './JobPreference';
import SocialMetadata from './SocialMetadata';
import ProfessionalExperiences from './ProfessionalExperiences';
import ProfessionalEducations from './ProfessionalEducations';
import ComplementaryEducations from './ComplementaryEducations';
import ProfessionalLanguages from './ProfessionalLanguages';
import CompanyQuestions from './CompanyQuestions';
import AvailabilityDates from './AvailabilityDates';
import CustomFields from './CustomFields';
import ExistingUser from './ExistingUser';
import RenderFormErrors from './RenderFormErrors';

const formName = 'registration';

const propTypes = {
  candidate: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    cvLibraryToken: PropTypes.string,
    desiredSalary: PropTypes.string,
    phone: PropTypes.string,
    professionalTitle: PropTypes.string,
    cvData: PropTypes.shape({
      fileName: PropTypes.string,
      fileType: PropTypes.string,
      fileUrl: PropTypes.string,
    }),
    userLocation: PropTypes.shape(),
  }),
  companyColor: PropTypes.string.isRequired,
  companyQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  formData: PropTypes.shape({
    company: PropTypes.shape(),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
    formatDate: PropTypes.func,
  }).isRequired,
  job: PropTypes.shape({
    company_id: PropTypes.number,
    id: PropTypes.number,
    job_setting: PropTypes.shape(),
    title: PropTypes.string,
    job_locations: PropTypes.arrayOf(PropTypes.shape({
      region: PropTypes.shape({
        name: PropTypes.string,
      }),
    })),
    order_date: PropTypes.string,
    activation_date: PropTypes.string,
  }).isRequired,
  legalMessage: PropTypes.string,
  legalInfoText: PropTypes.string,
  questionTypes: PropTypes.shape({
    checkbox: PropTypes.number,
    radio: PropTypes.number,
    select: PropTypes.number,
    text: PropTypes.number,
  }),
  submitting: PropTypes.bool.isRequired,
  languageList: PropTypes.arrayOf(PropTypes.string).isRequired,
  languageLevels: PropTypes.objectOf(PropTypes.number).isRequired,
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  customFieldsForm: PropTypes.arrayOf(PropTypes.shape({})),
  idDocumentFieldsForm: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  openedForm: PropTypes.bool.isRequired,
  openForm: PropTypes.func.isRequired,
  systemFieldsForm: PropTypes.objectOf(PropTypes.shape({})),
  submitFailed: PropTypes.bool.isRequired,
  syncErrors: PropTypes.shape({}).isRequired,
  anonymousJob: PropTypes.bool,
};

const defaultProps = {
  candidate: {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    gender: undefined,
    cvLibraryToken: undefined,
    desiredSalary: undefined,
    phone: undefined,
    professionalTitle: undefined,
    cvData: {
      fileName: undefined,
      fileType: undefined,
      fileUrl: undefined,
    },
    userLocation: {
      regionId: undefined,
    },
  },
  companyQuestions: [],
  countries: [],
  legalMessage: null,
  legalInfoText: null,
  questionTypes: {
    checkbox: 2,
    radio: 3,
    select: 1,
    text: 4,
  },
  customFieldsForm: undefined,
  idDocumentFieldsForm: {
    fields: [],
  },
  systemFieldsForm: undefined,
  anonymousJob: false,
};

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.initData = this.initData.bind(this);
    this.companyQuestionsPresenter = this.companyQuestionsPresenter.bind(this);
    this.fieldLabel = this.fieldLabel.bind(this);
    this.fieldValidation = this.fieldValidation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderField = this.renderField.bind(this);
    this.renderArrayFields = this.renderArrayFields.bind(this);
    this.validExistingEmail = this.validExistingEmail.bind(this);
    this.renderAllErrors = this.renderAllErrors.bind(this);
    this.handleSubmitFailed = this.handleSubmitFailed.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.idDocumentFieldValue = this.idDocumentFieldValue.bind(this);

    this.state = {
      showExistingEmail: false,
      showExistingCandidacy: false,
      hideAlert: true,
      formErrors: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.initData();
  }

  initData() {
    const {
      email, firstName, lastName, desiredSalary, gender,
      phone, professionalTitle, cvLibraryToken,
      userLocation: {
        regionId,
      },
      cvData: { fileUrl, fileName },
    } = this.props.candidate;

    const {
      customFieldsForm,
    } = this.props;

    const assetData = fileUrl === undefined ?
      { asset_type: 'curriculum' } :
      { file: fileUrl, file_file_name: fileName, asset_type: 'curriculum' };

    const regioData = (regionId === undefined || regionId === null) ?
      undefined :
      { value: regionId.uid, label: regionId.route };

    const candidateResponses = {};
    for (const customField of customFieldsForm) {
      candidateResponses[customField.id] = [];
      const fieldAnswers = customField.fields.map((field) => {
        let response;
        if (field.type === 'CustomField::Fields::MultiSelectType') {
          response = { options: [] };
        } else if (field.type === 'CustomField::Fields::SelectType' ||
        field.type === 'CustomField::Fields::RadioType') {
          response = { option_id: '' };
        } else if (field.type === 'CustomField::Fields::RangeType') {
          response = { range: [field.limitMin, field.limitMax] };
        } else if (field.type === 'CustomField::Fields::FileType') {
          response = { asset: {} };
        } else {
          response = { response: '' };
        }
        return { [field.fieldCode]: response };
      });
      candidateResponses[customField.id] = fieldAnswers;
    }

    const initData = {
      job_id: this.props.job.id,
      cv_library_token: cvLibraryToken,
      user: {
        email,
        user_representations_attributes: [
          {
            user_metadata_attributes: {
              first_name: firstName,
              last_name: lastName,
              gender,
              dni_type: this.idDocumentFieldValue(),
            },
            professional_metadata_attributes: {
              title: professionalTitle,
              phone,
              professional_experiences_attributes: [
                {
                  position: null,
                },
              ],
              professional_educations_attributes: [
                {
                  degree: null,
                },
              ],
              assets_attributes: [assetData],
            },
            user_location_attributes: {
              region_id: regioData,
            },
            job_preference_attributes: {
              desired_salary: desiredSalary,
            },
          },
        ],
      },
      custom_field_groups: {
        responses: candidateResponses,
      },
      responses_attributes: this.companyQuestionsPresenter(),
    };

    if (email) this.validExistingEmail(null, email);
    this.props.initialize(initData);
  }

  companyQuestionsPresenter() {
    return this.props.companyQuestions.map(question => (
      { id: question.id, question_type: question.question_type }
    ));
  }

  idDocumentFieldValue() {
    const { fields } = this.props.idDocumentFieldsForm;
    if (fields !== undefined && fields.length > 0) {
      // eslint-disable-next-line react/prop-types
      return fields[0].value;
    }

    return undefined;
  }

  fieldLabel(field, label) {
    return field.required
      ?
        <span title={label}>
          {label}<span className="require">*</span>
        </span>
      : label;
  }

  fieldValidation(field, extraValidations = null) {
    const validations = extraValidations ? [required].concat(extraValidations) : required;

    return field.required ? validations : extraValidations;
  }

  validExistingEmail(_event, newValue) {
    const {
      job: {
        id,
        company_id: companyId,
      },
    } = this.props;

    if (newValue) {
      const url = Routes.job_center_candidates_path({
        company_id: companyId,
        candidate: { email: newValue, job_id: id },
      });

      get(url).then((data) => {
        const {
          existing_candidate: showExistingEmail,
          applied_to_job: showExistingCandidacy,
        } = data;

        this.setState({ showExistingEmail, showExistingCandidacy });
      });
    }
  }

  handleSubmit(values) {
    this.setState({ formErrors: null });
    $('#loader-wrapper').parent().show();

    $.ajax({
      url: Routes.candidates_path(),
      beforeSend: xhr => xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')),
      type: 'POST',
      data: buildBody(values),
      contentType: false,
      processData: false,
      success: () => {
        $('#loader-wrapper').parent().hide();
      },
      error: (data) => {
        const dataProcessed = data;
        if (dataProcessed.status === 403) {
          const error = <FormattedMessage id="shared.form.errors.forbidden_firewall" />;
          dataProcessed['responseJSON'] = { errors: [error] };
        }
        this.setState({ formErrors: dataProcessed.responseJSON.errors });
        $('#loader-wrapper').parent().hide();
        document.querySelector('.form-wrapper').scrollIntoView({ behavior: 'smooth' });
      },
    });
  }

  flattenObject(obj, prefix = '') {
    const newObj = Object.keys(obj).reduce((acc, k) => {
      const innerObj = acc;
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[k] === 'object') Object.assign(acc, this.flattenObject(obj[k], (Number.isNaN(+k) || (!Number.isNaN(+k) && Array.isArray(obj[k])) ? `${pre}${k}` : `${prefix}[${k}]`)));
      if (obj[k] !== undefined && typeof obj[k] === 'string') innerObj[prefix] = obj[k];
      return innerObj;
    }, {});
    return newObj;
  }

  handleSubmitFailed() {
    this.setState({ hideAlert: false });
  }

  hideAlert() {
    this.setState({ hideAlert: true });
  }

  renderAllErrors() {
    const { syncErrors, submitFailed, intl: { messages } } = this.props;
    const { hideAlert } = this.state;

    if (!submitFailed) return null;
    const errors = [];
    const flattenObject = this.flattenObject(syncErrors);

    Object.keys(flattenObject).forEach((key) => {
      const element = document.querySelectorAll(`[data-input-name="${key}"]`)[0];
      if (element) errors.push({ label: element.getAttribute('data-label-text'), errorText: flattenObject[key], key });
    });

    if (!errors.length) return null;

    return (
      !hideAlert &&
        <div className="alert danger no-fading full">
          <Icon name="huge-alert-circle" klass="danger msg-icon" />
          <button type="button" onClick={this.hideAlert} className="close">
            <Icon name="huge-cancel-01" />
          </button>
          <div className="message">
            <h3 className="mb-small not-base">
              { errors.length === 1
                ?
                  <FormattedMessage id="jobs.job_centers.errors_alert_title_single" values={{ errors: errors.length }} />
                :
                  <FormattedMessage id="jobs.job_centers.errors_alert_title_multiple" values={{ errors: errors.length }} />
              }
            </h3>
            <ul>
              { errors.map(error => (
                <li key={error.key}>
                  { error.label }: { messages[error.errorText] }
                </li>
              ))
              }
            </ul>
          </div>
        </div>
    );
  }

  renderField(label, name, validate, placeholder, dataInputName, type = 'text') {
    return (
      <div data-input-name={dataInputName} data-label-text={typeof label === 'object' ? label.props.title : label}>
        <Field
          className="block-input"
          component={RenderField}
          label={label}
          name={name}
          type={type}
          validate={validate}
          placeholder={placeholder}
        />
      </div>
    );
  }

  renderArrayFields(component, name, options = {}) {
    const { systemFieldsForm, companyColor } = this.props;

    return (
      <FieldArray
        component={component}
        fieldLabel={this.fieldLabel}
        fieldValidation={this.fieldValidation}
        formName={formName}
        name={name}
        systemFieldsForm={systemFieldsForm}
        renderField={this.renderField}
        companyColor={companyColor}
        {...options}
      />
    );
  }

  render() {
    const {
      showExistingEmail,
      showExistingCandidacy,
      formErrors,
    } = this.state;

    const {
      handleSubmit,
      submitting,
      systemFieldsForm,
      idDocumentFieldsForm,
      customFieldsForm,
      legalMessage,
      questionTypes,
      companyQuestions,
      companyColor,
      intl: { messages, formatDate },
      candidate: { cvData },
      job, job: { job_setting: jobSetting },
      genders,
      anonymousJob,
      openedForm,
      openForm,
    } = this.props;
    const styleButtons = {
      backgroundColor: companyColor,
      border: `${companyColor} solid 1px`,
    };
    const style = { color: companyColor };

    const rgpdLabelText = (<span
      dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
        __html: legalMessage,
      }}
    />);
    const rgpdLabel = (
      <div className="register-message">
        <div className="text">
          {rgpdLabelText}
          <span className="require">*</span>
        </div>
      </div>
    );

    return (
      <div className={`form-wrapper${openedForm ? '' : ' is-hidden'}`}>
        <h1 className="offer-title">{job.title}</h1>
        <div className="date">
          {!jobSetting.hide_location &&
            job.job_locations.length && job.job_locations[0].region !== undefined &&
            <h2 className="location">
              {`${job.job_locations[0].region.name}`}
            </h2>
          }
          {!jobSetting.hide_publication_date && ` · ${messages['jobs.date']} ${formatDate(new Date(job.activation_date || job.order_date), { year: 'numeric', month: 'long', day: 'numeric' })}`}
        </div>
        <RenderFormErrors
          formErrors={formErrors}
        />

        { this.renderAllErrors() }
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          {
            !anonymousJob && showExistingEmail &&
              <ExistingUser
                showExistingCandidacy={showExistingCandidacy}
                showExistingEmail={showExistingEmail}
              />
          }

          <div className="link back-link" onClick={() => openForm(false)}>
            <Icon name="huge-arrow-left-02" />
            {messages['jobs.link_to_job']}
          </div>

          <div className="title form-title">{messages['jobs.apply']}</div>
          <div className="subtitle">{messages['jobs.jobcenter_message']}</div>

          <FormSection name="user">
            <PersonalInfo
              fieldLabel={this.fieldLabel}
              fieldValidation={this.fieldValidation}
              formName={formName}
              systemFieldsForm={systemFieldsForm}
              idDocumentFieldsForm={idDocumentFieldsForm}
              renderField={this.renderField}
              cvData={cvData}
              validExistingEmail={this.validExistingEmail}
              showExistingEmail={showExistingEmail}
              genders={genders}
              showExistingCandidacy={showExistingCandidacy}
              anonymousJob={anonymousJob}
            />
            <FormSection name="employments_attributes[0]">
              <Employments
                companyId={this.props.job.company_id}
                fieldLabel={this.fieldLabel}
                fieldValidation={this.fieldValidation}
                formName={formName}
                systemFieldsForm={systemFieldsForm}
                renderField={this.renderField}
              />
            </FormSection>

            <FormSection name="user_representations_attributes[0]">
              <FormSection name="user_location_attributes">
                <Location
                  countries={this.props.countries}
                  fieldLabel={this.fieldLabel}
                  fieldValidation={this.fieldValidation}
                  systemFieldsForm={systemFieldsForm}
                  renderField={this.renderField}
                />
              </FormSection>
              <FormSection name="job_preference_attributes">
                <JobPreference
                  fieldLabel={this.fieldLabel}
                  fieldValidation={this.fieldValidation}
                  systemFieldsForm={systemFieldsForm}
                  renderField={this.renderField}
                />
              </FormSection>
              <FormSection name="professional_metadata_attributes">
                <AvailabilityDates
                  fieldValidation={this.fieldValidation}
                  systemFieldsForm={systemFieldsForm}
                  messages={messages}
                />
              </FormSection>
              <FormSection name="social_metadata_attributes">
                <SocialMetadata
                  fieldLabel={this.fieldLabel}
                  fieldValidation={this.fieldValidation}
                  systemFieldsForm={systemFieldsForm}
                  renderField={this.renderField}
                />
              </FormSection>
              <FormSection name="professional_metadata_attributes">
                {
                  this.renderArrayFields(
                    ProfessionalExperiences,
                    'professional_experiences_attributes',
                  )
                }
                {
                  this.renderArrayFields(
                    ProfessionalEducations,
                    'professional_educations_attributes',
                  )
                }
                {
                  this.renderArrayFields(
                    ComplementaryEducations,
                    'complementary_educations_attributes',
                    {
                      companyColor,
                    },
                  )
                }
                {
                  this.renderArrayFields(
                    ProfessionalLanguages,
                    'professional_languages_attributes',
                    {
                      languageLevels: this.props.languageLevels,
                      languageList: this.props.languageList,
                      companyColor,
                    },
                  )
                }
              </FormSection>
            </FormSection>
          </FormSection>
          { customFieldsForm.length > 0 &&
            <FormSection name="custom_field_groups">
              <CustomFields
                customFieldsForm={customFieldsForm}
                style={style}
              />
            </FormSection>
          }
          <CompanyQuestions
            questionTypes={questionTypes}
            companyQuestions={companyQuestions}
            formName={formName}
            companyColor={companyColor}
          />

          <div className="conditions-job-center" data-input-name="terms_and_conditions" data-label-text={messages['jobs.terms_and_conditions']}>
            <Field
              component={RenderCheckboxField}
              label={rgpdLabel}
              name="terms_and_conditions"
              validate={required}
              className="block-input input_checkbox form-check"
            />
          </div>
          <div className="button-content">
            <button
              id="form-submit-button"
              style={styleButtons}
              className="employment-center-register-btn submit-form button"
              type="submit"
              onClick={this.handleSubmitFailed}
              disabled={submitting || showExistingCandidacy}
            >
              {messages['jobs.send_cv']}
            </button>
          </div>
          <div
            className="conditions-rgpd"
            dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
              __html: this.props.legalInfoText,
            }}
          />
        </form>
      </div>
    );
  }
}

function handleErrors() {
  document.getElementById('form-submit-button').click();
  document.querySelector('.form-wrapper').scrollIntoView({ behavior: 'smooth' });
}

const formOptions = {
  form: formName,
  onSubmitFail: handleErrors,
};

RegistrationForm.propTypes = propTypes;
RegistrationForm.defaultProps = defaultProps;

const wrappedComponent = connect(state =>
  ({
    syncErrors: getFormSyncErrors(formName)(state),
  }))(injectIntl(RegistrationForm));

export default reduxForm(formOptions)(wrappedComponent);
