import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import RenderField from 'common-components/form/RenderField';
import RenderSelectField from 'common-components/form/RenderSelectField';
import {
  rutFormat, rutCodeValidation, dniValidation, nieValidation, cpfBrValidation, required,
} from 'utils/form/validations';
import Label from 'common-components/form/Label';

const propTypes = {
  idDocumentFieldsForm: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape()),
    required: PropTypes.bool,
  }),
  dniType: PropTypes.string,
  intl: PropTypes.shape({
    messages: PropTypes.shape(),
  }).isRequired,
};

const defaultProps = {
  idDocumentFieldsForm: {
    fields: [],
    required: false,
  },
  dniType: undefined,
};

class IdDocumentField extends React.Component {
  constructor(props) {
    super(props);
    this.idDocumentInput = React.createRef();
    this.dniValidations = this.dniValidations.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.fieldLabel = this.fieldLabel.bind(this);
    this.state = {
      dniSelected: props.dniType,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      idDocumentFieldsForm:
      { fields: dniFields }, dniType,
    } = this.props;
    if (dniType) {
      this.setState({ dniSelected: dniType });
    } else {
      this.setState({ dniSelected: dniFields[0].value });
    }
  }

  dniValidations(dniSelected, requireValidation) {
    const validations = requireValidation ? [required] : [];

    switch (dniSelected) {
      case 'rut_cl':
        validations.push(rutFormat, rutCodeValidation);
        return validations;
      case 'dni_es':
        validations.push(dniValidation);
        return validations;
      case 'nie_es':
        validations.push(nieValidation);
        return validations;
      case 'cpf_br':
        validations.push(cpfBrValidation);
        return validations;
      default:
        return validations;
    }
  }

  fieldLabel(requiredValidation, label) {
    return requiredValidation ? (
      <span title={label}>
        {label}
        <span className="require">*</span>
      </span>
    )
      : label;
  }

  selectChange(e, val) {
    this.setState({ dniSelected: val });
    this.idDocumentInput.focus();
  }

  render() {
    const userMetadata = 'user_representations_attributes[0].user_metadata_attributes.';
    const { dniSelected } = this.state;
    const {
      idDocumentFieldsForm:
      {
        fields,
        required: requireValidation,
      }, intl: { messages },
    } = this.props;

    return (
      <div className="form-group">
        <Label label={this.fieldLabel(requireValidation, messages['jobs.identification_number'])} />
        <div className="row">
          { fields.length > 1 &&
            <Field
              component={RenderSelectField}
              className="first col-6"
              name={`${userMetadata}dni_type`}
              onChange={(e, val) => this.selectChange(e, val)}
              options={fields}
            />
          }
          <Field
            component={RenderField}
            placeholder={messages['jobs.identification_number']}
            className="last col-6"
            type="text"
            validate={this.dniValidations(dniSelected, requireValidation)}
            name={`${userMetadata}dni`}
            reference={(el) => (this.idDocumentInput = el)}
          />
          { fields.length < 2 &&
            <Field
              component="input"
              type="hidden"
              className="first col-6"
              name={`${userMetadata}dni_type`}
            />
          }
        </div>
      </div>
    );
  }
}

IdDocumentField.propTypes = propTypes;
IdDocumentField.defaultProps = defaultProps;
export default injectIntl(IdDocumentField);
